<template>
  <SvgIcon
    :style="{marginLeft: '-5px', cursor: 'pointer'}"
    :name="isFavourite ? 'favourites-on' : 'favourites-off'"
    :size="24"
    @click="toggleFavourite"
  />
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import storage from '@/services/storage.service'

export default {
  name: 'AppFavourite',
  props: {
    product: Object,
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      isFavourite: storage.isFavourite(this.product),
    };
  },
  methods: {
    toggleFavourite() {
      if (this.isFavourite) {
        storage.removeFavourite(this.product);
      } else {
        storage.addFavourite(this.product);
      }

      this.isFavourite = storage.isFavourite(this.product);
    }
  }
}
</script>
