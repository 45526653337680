import { getCollection } from '../../services/api.service';

const state = {
  collection: null,
  filters: [],
  isFetching: false,
  error: null,
};

const getters = {
  getCrumbs(state) {
    return state.collection ? [{title: state.collection.title, id: state.collection.slug}] : [];
  }
};

const actions = {
  fetchCollection({ commit }, slug) {
    commit('fetchRequest');
    getCollection(slug)
      .then(({ collection, filters }) => commit('fetchCollectionSuccess', { collection, filters }))
      .catch((error) => commit('fetchError', { error }))
  },
};

const mutations = {
  fetchRequest(state) {
    state.isFetching = true;
    state.collection = null;
    state.error = null;
  },
  fetchError(state, { error }) {
    state.isFetching = false;
    state.error = error;
  },
  fetchCollectionSuccess(state, { collection, filters }) {
    state.collection = collection;
    state.filters = filters;
    state.isFetching = false;
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
