import { createStore, createLogger } from 'vuex';
import category from './modules/category';
import collection from './modules/collection';
import product from './modules/product';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    category,
    collection,
    product
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
