<template>
  <router-link :to="`/category/${product.category.slug}/product/${product.sku}`">
    <ProductImage
      :title="title"
      :image="image"
      :active="active"
    />
    <div class="title">{{ title }}</div>
  </router-link>
</template>

<script>
import ProductImage from '@/components/ProductImage';

export default {
  name: 'GroupProduct',
  props: {
    product: Object,
    title: String,
    image: String,
    active: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    ProductImage,
  },
}
</script>
<style lang="scss" scoped>
  @import "../styles/variables";

  .title {
    color: $dark-gray;
    font-size: 12px;
    line-height: 1;
    margin-top: 5px;
  }
</style>
