<template>
  <button
      class="base-button"
      v-bind:class="[variant, size]">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    variant: String, // orange, orange-light, green, transparent
    size: String, // small
  },
}
</script>

<style lang="scss" scoped>
  @import '../styles/mixins.scss';
  @import '../styles/variables.scss';

  .base-button {
    border: none;
    background: transparent;
    border-radius: 24px;
    color: #3B3F3D;
    padding: 6px 15px;
    cursor: pointer;

    @include media-rule(fullhd) {
      padding: 10px 24px
    }
    @include media-rule(twok) {
      padding: 10px 24px
    }

    &.orange {
      background: #FFAE8A;
    }

    &.orange-light {
      background: #FFAE8A;
      color: #fff;
    }

    &.green {
      background: #477762;
      color: #fff;
    }

    &.transparent {
      border: 1px solid $gray;
    }

    //sizes
    &.small {
      @include font-variant(12, 16, 400);

      padding: 4px 8px;
      border-radius: 12px;
    }
  }
</style>
