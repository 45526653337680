import { getCategories, getCategory, getFilters } from '../../services/api.service';

const state = {
  categories: [],
  category: null,
  filters: [],
  isFetching: false,
  error: null,
};

const getters = {
  getCrumbs(state) {
    return state.category ? [{title: state.category.title, id: state.category.slug}] : [];
  }
};

const actions = {
  fetchCategories({ commit }) {
    commit('fetchRequest');
    getCategories()
      .then((categories) => commit('fetchCategoriesSuccess', { categories }))
      .catch((error) => commit('fetchError', { error }))
  },
  fetchCategory({ commit }, slug) {
    commit('fetchRequest');
    getCategory(slug)
      .then(({ category, filters }) => commit('fetchCategorySuccess', { category, filters }))
      .catch((error) => commit('fetchError', { error }))
  },
  fetchFilters({ commit }, term) {
    commit('fetchRequest');
    getFilters(term)
      .then((filters) => commit('fetchFiltersSuccess', { filters }))
      .catch((error) => commit('fetchError', { error }))
  },
};

const mutations = {
  fetchRequest(state) {
    state.isFetching = true;
    state.categories = [];
    state.category = null;
    state.error = null;
  },
  fetchError(state, { error }) {
    state.isFetching = false;
    state.error = error;
  },
  fetchCategoriesSuccess(state, { categories }) {
    state.categories = categories;
    state.isFetching = false;
    state.error = null;
  },
  fetchCategorySuccess(state, { category, filters }) {
    state.category = category;
    state.filters = filters;
    state.isFetching = false;
    state.error = null;
  },
  fetchFiltersSuccess(state, { filters }) {
    state.filters = filters;
    state.isFetching = false;
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
