<template>
  <DotsLoader v-if="isFetching" />
  <CatalogHeader/>
  <div class="sub-header">
    <BreadCrumbs
        v-if="crumbs"
      :class="{mobileHidden: filtersToggled}"
      :crumbs="crumbs"
      @selected="selected"
    />
    <div class="mobile-filters" v-if="false">
      <SvgIcon name="filters" :size=32 @click="toggleFilters" v-if="!filtersToggled"/>
      <BaseButton
          variant="orange-light"
          size="small"
          v-if="Object.keys(itemsSelected).length > 1 && !filtersToggled"
      ><b>{{ Object.keys(itemsSelected).length }}</b></BaseButton>
    </div>
  </div>
  <div class="CategoryPage">
    <div class="content" :class="{mobileHidden: filtersToggled}">
      <div class="products">
        <div v-for="(product, index) in products" :key="index">
          <ProductLink
            :product="product"
            :imageUrl="getImage(product)"
            :title="product.title"
            :vendorCode = "product.sku"
          />
        </div>
      </div>
      <NoProducts v-if="products.length === 0" />
    </div>
  </div>
  <CatalogFooter />
</template>

<script>
import { ref } from 'vue'
import { getThumbImageUrl } from '../services/api.service';
import filters from '@/demo-files/filters.json';
import storage from '@/services/storage.service'
import CatalogHeader from '@/components/CatalogHeader';
import CatalogFooter from '@/components/CatalogFooter';
import BreadCrumbs from '@/components/BreadCrumbs';
import BaseButton from '@/components/BaseButton';
import ProductLink from '@/components/ProductLink';
import SvgIcon from '@/components/SvgIcon';
import NoProducts from '@/components/NoProducts';
import DotsLoader from '@/components/DotsLoader';
import { getProductsBySkuList } from '../services/api.service';

const itemsSelected = ref([]);
const filtersLength = Object.keys(filters).length > 1 ? Object.keys(filters).length : null

export default {
  name: 'FavouritesPage',
  components: {
    SvgIcon,
    BaseButton,
    BreadCrumbs,
    CatalogHeader,
    CatalogFooter,
    ProductLink,
    NoProducts,
    DotsLoader,
  },
  data(){
    return {
      filters,
      products: [],
      isFetching: false,
      itemsSelected,
      filtersLength,
      filtersToggled: false,
      crumbs: null,
    }
  },
  mounted() {
    this.crumbs = ['Избранное'];
    this.isFetching = true;

    getProductsBySkuList(storage.getFavourites().map(product => product.sku))
      .then((products) => {
        this.products = products;
      })
      .finally(() => {
        this.isFetching = false;
      });
  },
  methods: {
    getImage(product) {
      return getThumbImageUrl(product.images[0]);
    },
    unCheckAll(){
      this.itemsSelected = []
    },
    selected() {

    },
    toggleFilters() {
      this.filtersToggled = !this.filtersToggled
    }
  },
}

</script>

<style lang="scss" scoped>
@import "../styles/mixins";
@import "../styles/variables";

.CategoryPage {
  @include side-padding;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .sidebar {
    width: 240px;
    flex: 1;
    padding-bottom: 40px;
    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 26px;
      .icon {
        display: none;
        @include media-rule(mobile) {
          display: block;
        }
      }
    }
    .filter-action {
      margin: 30px 0 0;
      padding-right: 7px;
      .text {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        color: white;
        .icon {
          height: 1em;
          > svg {
            height: 100%;
          }
        }
      }
    }

    @include media-rule(mobile) {
      display: none;
      width: unset;

      &.showMobile {
          display: block;
        }

    }
  }
  .filter-type {
    margin: 24px 0 14px 0;

    @include media-rule(twok) {
      margin: 38px 0 16px 0;
    }
  }

  .products {
    width: 100%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
    gap: 16px;

    @media screen and (min-width: $BreakpointSM) {
      grid-template-columns: repeat(auto-fit, minmax(234px, 1fr));
      padding-left: 32px;
    }
  }
}

.sub-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;

  .mobile-filters {
    @include side-padding;
    margin: 22px 0;
    align-items: center;
    justify-content: center;
    display: none;

    @include media-rule(mobile) {
      display: flex;
    }
  }
}

@include media-rule(mobile) {
  .mobileHidden {
    display: none;
  }
  .filters-header {
    margin: -20px 0 20px 0;
    @include font-variant(20, 26, 500)
  }
}
</style>
