<template>
  <svg
      class="icon"
      :width="size"
      :height="size"
      :class="className"
      xmlns="http://www.w3.org/2000/svg"
  >
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconPath() {
      return `#${this.name}`
    },
    className() {
      return `icon--${this.name}`
    },
  },
});
</script>
