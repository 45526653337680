<template>
<div class="qr-view" v-bind:class="{ offset: withOffset }">
  <h2>Переход на онлайн версию</h2>
  <div class="qrs">
    <div class="item">
      <div class="qr">
        <SvgIcon name="qr-swensa-vk" :size="200" />
      </div>
      <div class="info">
        <div class="text">
          Отсканируйте QR-code для перехода<br /> в нашу группу Вконтакте<br />
          <a href="https://vk.com/public211627222">
            https://vk.com/public211627222
          </a>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="qr">
        <SvgIcon name="qr-swensa" :size="200" />
      </div>
      <div class="info">
        <div class="text">
          Отсканируйте QR-code для<br /> перехода по адресу<br />
          <a href="https://swensa.ru/catalog/">
            https://swensa.ru/catalog/
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="back">
    <BaseButton
      class="return"
      variant="green"
      @click="$emit('closeQr')"
    >
      Вернуться к просмотру
    </BaseButton>
  </div>
</div>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import SvgIcon from '@/components/SvgIcon';

export default {
  name: 'ShowQr',
  components: {SvgIcon, BaseButton },
  props: {
    withOffset: Boolean
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/mixins";

.qr-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  height: 100vh;
  width: 100%;
  padding: 176px 120px 100px;
  overflow: hidden;
  background: #FFFFFF;
  text-align: center;
  transition: all .3s ease-in-out;

  &.offset {
    padding-top: 116px;
  }
  > h2 {
    margin-top: 47px;
    @include media-rule(fullhd) {
      margin-top: 61px;
    }
    @include media-rule(twok) {
      margin-top: 82px;
    }
  }
  .qrs {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 200px;

    @include media-rule(fullhd) {
      gap: 480px;
    }
    @include media-rule(twok) {
      gap: 665px
    }

    > .item {
      .qr {
        width: 300px;
        height: 300px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      @include media-rule(desktop) {
        .qr {
          margin: 40px 0 27px 0;
        }
      }
      @include media-rule(fullhd) {
        flex-basis: 400px;
        .qr {
          margin: 100px 0 122px 0;
        }
      }
      @include media-rule(twok) {
        flex-basis: 430px;
        .qr {
          margin: 190px 0 122px 0;
        }
      }
    }
  }

  .back {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-top: 75px;
    @include media-rule(desktop) {
      margin-top: 30px;
    }

    .return {
      margin-left: auto;
    }
  }
}
</style>