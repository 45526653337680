<template>
  <div class="medium-fs bold filters-header" v-if="filtersToggled && !menuToggled">
    Фильтры
    <div class="centered">
      <SearchField v-if="showSearchBar" :isMinimized="true"/>
    </div>
    <svg @click="toggleMenu" style="margin-left: 10px;" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C8 15.4477 8.44772 15 9 15L24 15C24.5523 15 25 15.4477 25 16C25 16.5523 24.5523 17 24 17L9 17C8.44772 17 8 16.5523 8 16Z" fill="#3B3F3D"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 22C8 21.4477 8.44772 21 9 21L23 21C23.5523 21 24 21.4477 24 22C24 22.5523 23.5523 23 23 23L9 23C8.44772 23 8 22.5523 8 22Z" fill="#3B3F3D"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 10C8 9.44772 8.44772 9 9 9L21 9C21.5523 9 22 9.44772 22 10C22 10.5523 21.5523 11 21 11L9 11C8.44772 11 8 10.5523 8 10Z" fill="#3B3F3D"/>
    </svg>
  </div>
  <ShowMenu
    :withOffset="false"
    :menuToggled="menuToggled"
    :onToggleMenu="toggleMenu"
  />
  <div class="header" v-if="!menuToggled">
    <div class="medium-fs bold" v-if="!filtersToggled && !menuToggled">
      Фильтры <span class="green" v-if="getAppliedFilerLength()">({{ getAppliedFilerLength() }})</span>
    </div>
    <SvgIcon
        v-if="!menuToggled"
        name="arr-back"
        :size="32"
        :style="{marginLeft: '-10px'}"
        @click="toggleFilters"
    />
    <div v-if="!menuToggled">
      <BaseButton
          variant="transparent"
          size="small"
          v-if="getAppliedFilerLength() > 0"
          @click="clearFilter">
        Сбросить все <b>{{ getAppliedFilerLength() }}</b>
      </BaseButton>
      <BaseButton
          @click="applyFilterAndToggle"
          variant="orange-light"
          size="small"
          v-if="filtersToggled && getAppliedFilerLength() > 0"
          :style="{marginLeft: '10px'}"
      >
        Применить
      </BaseButton>
    </div>
  </div>
  <template v-if="!menuToggled">
    <template v-for="(filter, filterIndex) in filters" :key="`filter${filterIndex}`">
      <template
          v-for="(property, propertyIndex) in getVisibleProperties(filter)"
          :key="`filter${filterIndex}property${propertyIndex}`"
      >
        <div class="filter-type">
          <b v-if="filter.widget !== 'toggle'">{{ filter.title }}</b>
          <CheckToggle
              v-if="filter.widget === 'toggle' && getVisibleProperties(filter).length === 1"
              :title="filter.title"
              :trueValue="getPropertyTrueValue(property)"
              v-model="appliedFilter.properties[property.id]"
              :key="`filter${filterIndex}property${propertyIndex}value${valueIndex}`"
          />
        </div>
        <div class="filter-type" v-if="getVisibleProperties(filter).length > 1">
          <b v-if="filter.widget !== 'toggle'">{{ property.title }}</b>
          <CheckToggle
              v-if="filter.widget === 'toggle'"
              :title="property.title"
              :trueValue="getPropertyTrueValue(property)"
              v-model="appliedFilter.properties[property.id]"
              :key="`filter${filterIndex}property${propertyIndex}value${valueIndex}`"
          />
        </div>
        <div :class="{'filter-container': true, 'opened': isFilterOpened(property.id)}">
          <template v-for="(value, valueIndex) in property.values">
            <CheckButton
                v-if="filter.widget === 'button'"
                :item="value"
                v-model="appliedFilter.properties[property.id]"
                :key="`filter${filterIndex}property${propertyIndex}value${valueIndex}`"
            />
            <CheckBox
                v-if="filter.widget === 'checkbox'"
                :item="value"
                v-model="appliedFilter.properties[property.id]"
                :key="`filter${filterIndex}property${propertyIndex}value${valueIndex}`"
            />
          </template>
        </div>
        <b
            v-if="property.values.length > 5"
            class="green filter-open"
            @click="() => toggleOpenedFilter(property.id)"
        >
          {{ isFilterOpened(property.id) ? 'Свернуть' : 'Развернуть' }}
        </b>
      </template>
    </template>
  </template>
  <div v-if="!menuToggled">
    <BaseButton :style="{margin: '20px auto'}" v-if="filtersToggled" variant="orange-light" @click="applyFilterAndToggle">
      <div class="text">
        Применить
      </div>
    </BaseButton>
    <BaseButton v-else class="filter-action" variant="orange" @click="applyFilter">
      <div class="text">
        Применить
        <div class="icon">
          <SvgIcon name="arr-r-lght" :size="20" />
        </div>
      </div>
    </BaseButton>
  </div>
</template>
<script>
import SearchField from '@/components/SearchField';
import SvgIcon from '@/components/SvgIcon';
import CheckButton from '@/components/CheckButton';
import CheckBox from '@/components/CheckBox';
import CheckToggle from '@/components/CheckToggle';
import BaseButton from '@/components/BaseButton';
import ShowMenu from '@/components/ShowMenu';
import isKiosk from '../helpers/isKiosk';
import storage from '../services/storage.service';

export default {
  name: 'AppFilters',
  expose: ['toggleFilters', 'getAppliedFilerLength'],
  props: {
    filters: Array,
    onClearFilter: Function,
    onApplyFilter: Function,
    onToggleFilter: Function,
  },
  components: {
    SvgIcon,
    BaseButton,
    CheckButton,
    CheckBox,
    CheckToggle,
    ShowMenu,
    SearchField,
  },
  data(){
    return {
      openedFilters: [],
      filterSubmitted: false,
      filtersToggled: false,
      menuToggled: false,
      appliedFilter: storage.getFilter(this.$route.path) || this.getDefaultFilter(),
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.initFilter();
      }
    },
  },
  computed: {
    showSearchBar() {
      return !isKiosk();
    }
  },
  mounted() {
    this.initFilter();
  },
  methods: {
    initFilter() {
      this.appliedFilter = storage.getFilter(this.$route.path) || this.getDefaultFilter();
      this.filterSubmitted = this.getAppliedFilerLength() > 0;
    },
    getPropertyTrueValue(property) {
      let trueValue = property.values[0] || {};

      property.values.map((value) => {
        if (value.title.toLowerCase() === 'да') {
          trueValue = value;
        }
      });

      return trueValue;
    },
    getVisibleProperties(filter) {
      return filter.properties.filter((prop) => prop.values.length > 1);
    },
    clearFilter() {
      this.appliedFilter = this.getDefaultFilter();

      if (this.filterSubmitted) {
        this.applyFilter();
        this.filterSubmitted = false;
      }

      this.onClearFilter();
    },
    applyFilterAndToggle() {
      this.applyFilter();
      this.toggleFilters();
    },
    applyFilter() {
      this.filterSubmitted = true;
      storage.addFilter(this.$route.path, this.appliedFilter);
      this.onApplyFilter(this.appliedFilter);
      window.scrollTo(0, 0);
    },
    getAppliedFilerLength() {
      let appliedFilterLength = 0;

      Object.keys(this.appliedFilter.properties).map((propertyId) => {
        appliedFilterLength += this.appliedFilter.properties[propertyId].length;
      });

      return appliedFilterLength;
    },
    getDefaultFilter() {
      const defaultFilter = {
        properties: {},
      };

      this.filters.map((filter) => {
        filter.properties.map((property) => {
          defaultFilter.properties[property.id] = [];
        });
      });

      return defaultFilter;
    },
    toggleOpenedFilter(toggledFilter) {
      if (this.isFilterOpened(toggledFilter)) {
        this.openedFilters = this.openedFilters.filter(filter => filter !== toggledFilter);
      } else {
        this.openedFilters.push(toggledFilter);
      }
    },
    isFilterOpened(filter) {
      return this.openedFilters.indexOf(filter) >= 0;
    },
    toggleFilters() {
      this.filtersToggled = !this.filtersToggled;
      this.onToggleFilter(this.filtersToggled);
    },
    toggleMenu() {
      this.menuToggled = !this.menuToggled;
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "../styles/mixins";

  .filter-action {
    margin: 30px 0 0;
    padding-right: 7px;
    .text {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      color: white;
      .icon {
        height: 1em;
        > svg {
          height: 100%;
        }
      }
    }
  }

  .filter-type {
    margin: 24px 0 14px 0;

    @include media-rule(twok) {
      margin: 38px 0 16px 0;
    }
  }

  .filter-container {
    max-height: 250px;
    overflow-y: auto;

    &.opened {
      max-height: 500px;
    }
  }

  .filter-open {
    cursor: pointer;
    display: block;
    margin-top: 15px;
  }

  .filters-header {
    display: flex;
    align-items: center;

    .centered {
      flex-grow: unset;
      margin: 0 0 0 auto;
    }
  }
</style>
