<template>
  <div class="CatalogHeader" ref="header" v-bind:class="{sticky: isScrolled}">
    <div class="header">
      <div class="go-to-lock" @click="goToLock" v-if="false"/>
      <div class="stripes">
        <div v-for="n in 7" v-bind:key="n" :class="'item-' + n"/>
      </div>
      <div :class="{content: true, 'mobile-hidden': isMobileHidden, '-search-open': isSearchFieldActive}">
        <router-link to="/catalog" class="logo" :class="{'-search-open': isSearchFieldActive}">
          <img src="./../assets/Horizontal_Full_Swensa.svg" />
        </router-link>
        <div class="centered">
          <SearchField v-if="showSearchBar" :isMinimized="isScrolled" :onToggle="onSearchFieldActiveToggle" />
        </div>
        <div class="qr-code" @click="showQr" v-if="showQRCode">
          <SvgIcon size="32" name="qrcode" v-if="!isQrShown"/>
          <SvgIcon size="32" name="close" v-if="isQrShown"/>
        </div>
        <svg class="mobileMenu" @click="toggleMenu" style="margin-left: 10px;" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C8 15.4477 8.44772 15 9 15L24 15C24.5523 15 25 15.4477 25 16C25 16.5523 24.5523 17 24 17L9 17C8.44772 17 8 16.5523 8 16Z" fill="#3B3F3D"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 22C8 21.4477 8.44772 21 9 21L23 21C23.5523 21 24 21.4477 24 22C24 22.5523 23.5523 23 23 23L9 23C8.44772 23 8 22.5523 8 22Z" fill="#3B3F3D"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 10C8 9.44772 8.44772 9 9 9L21 9C21.5523 9 22 9.44772 22 10C22 10.5523 21.5523 11 21 11L9 11C8.44772 11 8 10.5523 8 10Z" fill="#3B3F3D"/>
        </svg>
      </div>
    </div>
    <ShowMenu
      :withOffset="!isScrolled"
      :menuToggled="menuToggled"
      :onToggleMenu="toggleMenu"
    />
    <transition name="qr">
      <ShowQr
        v-if="isQrShown"
        :withOffset="isScrolled"
        @closeQr="isQrShown = !isQrShown"
      />
    </transition>
  </div>
  <div class="sentinal" ref="sentinal"></div>
</template>

<script>
  import SearchField from '@/components/SearchField';
  import SvgIcon from '@/components/SvgIcon';
  import ShowQr from '@/components/ShowQr';
  import ShowMenu from '@/components/ShowMenu';
  import st from '@/services/storage.service'
  import router from '@/router';
  import isKiosk from '../helpers/isKiosk';

  export default {
    name: 'CatalogHeader',
    props: {
      isMobileHidden: {
        type: Boolean,
        default: () => false,
      },
    },
    components: {
      SvgIcon,
      SearchField,
      ShowQr,
      ShowMenu,
    },
    data() {
      return {
        scrollY: null,
        headerTop: 0,
        isScrolled: false,
        isQrShown: false,
        menuToggled: false,
        isSearchFieldActive: false,
      }
    },
    mounted () {
      const handler = (entries) => this.isScrolled = !entries[0].isIntersecting;
      this.observer = new window.IntersectionObserver(handler)
      this.observer.observe(this.$refs.sentinal)
    },
    computed: {
      showSearchBar() {
        return !isKiosk();
      },
      showQRCode() {
        return isKiosk();
      },
    },
    methods: {
      onSearchFieldActiveToggle(isSearchFieldActive) {
        this.isSearchFieldActive = isSearchFieldActive;
      },
      showQr() {
        if (!this.isQrShown) {
          document.body.style.overflow = "hidden";
          this.isQrShown = true
        } else {
          document.body.style.overflow = "auto";
          this.isQrShown = false
        }
      },
      goToLock() {
        router.push('/')
        st.clearRecently()
      },
      toggleMenu() {
        this.menuToggled = !this.menuToggled;
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";

@mixin sticky-header {
  position: sticky;
  top: 0;
  .header {
    .stripes {
      height: 0;
      @include media-rule(mobile) {
        height: 16px;
      }
    }

    .content {
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;

      .qr-code {
        width: 32px;
        height: 32px;
      }

      .logo {
        &.-search-open {
          display: none;
          @media screen and (min-width: $BreakpointSM) {
            display: block;
          }
        }
      }

      @include media-rule(desktop) {
        .logo {
          max-width: 91px;
        }
      }
      @include media-rule(fullhd) {
        .logo {
          max-width: 100px;
        }
      }
      @include media-rule(twok) {
        padding-top: 20px;
        padding-bottom: 20px;
        .logo {
          max-width: 147px;
        }
        .qr-code {
          width: 48px;
          height: 48px;
        }
      }

      .centered {
        flex-grow: 1;
        // margin: 0 0 0 auto;
      }

      @include media-rule(mobile) {
        height: 100px;
        .logo {
          max-width: unset;
        }
      }
    }
    .qr-code {
      background: unset;
      @include media-rule(mobile) {
        display: none;
      }
    }
  }
}

.CatalogHeader {
    width: 100%;
    z-index: 2000;

    .mobileMenu {
      display: inline-block;
      @media screen and (min-width: $BreakpointSM) {
        display: none;
      }
    }

    .header {
      position: relative;

      .go-to-lock {
        position: absolute;
        z-index: 50;
        width: 100px;
        height: 100px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .stripes {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 16px;
        overflow: hidden;
        transition: height .3s ease-in-out;

        > div {
          width: 14.3%;
          height: 100%;
          transform: skewX(-45deg);

          &:first-child {
            margin-left: -3%;
            width: 18.3%;
          }
          &:last-child {
            margin-right: -3%;
            width: 18.3%;
          }
        }

        .item {
          &-1{
            background: #477762;
          }
          &-2{
            background: #EDF2F0;
          }
          &-3{
            background: #FDFDFD;
          }
          &-4{
            background: #FFFFFF;
          }
          &-5{
            background: #FFFFFF;
          }
          &-6{
            background: $bg-secondary;
          }
          &-7{
            background: #FFAE8A;
          }
        }
      }

      .content {
        @include side-padding;

        &.mobile-hidden {
          @include media-rule(mobile) {
            display: none;
          }
        }

        background: $bg-secondary;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        transition: height .3s ease-in-out;

        padding: 16px $SidePaddingMB 16px $SidePaddingMB;

        @media screen and (min-width: $BreakpointSM) {
          padding: 20px $SidePaddingSM 20px $SidePaddingSM;
        }

        @media screen and (min-width: $BreakpointMD) {
          padding: 20px $SidePaddingMD 20px $SidePaddingMD;
        }

        @media screen and (min-width: $BreakpointLG) {
          padding: 32px $SidePaddingLG 32px $SidePaddingLG;
        }

        .logo {
          transition: all .2s ease-in-out;
          
          img {
            width: 100%;
             max-width: 144px;
           
           @media screen and (min-width: $BreakpointSM) {
              max-width: 144px;
            }

            @media screen and (min-width: $BreakpointMD) {
              max-width: 153px;
            }

            @media screen and (min-width: $BreakpointLG) {
              max-width: 196px;
            }
          }
        }

        .centered {
          flex-grow: 1;
          margin: auto 49px;
          @include media-rule(mobile) {
            flex-grow: unset;
            margin: 0;
          }
        }

        .qr-code {
          @include ripple($gray);

          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          flex-basis: 50px;
          border-radius: 50%;
          background: #FFFFFF;
          overflow: hidden;
          @include media-rule(fullhd) {
            width: 76px;
            height: 76px;
            flex-basis: 76px;
            > svg {
              width: 40px;
              height: 40px;
            }
          }
          @include media-rule(twok) {
            width: 96px;
            height: 96px;
            flex-basis: 96px;
            > svg {
              width: 60px;
              height: 60px;
            }

          }
          @include media-rule(mobile) {
            display: none;
          }
        }
      }
  }

  @media screen and (max-width: $BreakpointSM) {
    @include sticky-header;
  }

  &.sticky {
    @include sticky-header;
  }
}

.qr-enter-from {
  opacity: 0;
}

.qr-leave-to {
  opacity: 0;
}

.qr-enter-from .qr-container,
.qr-leave-to .qr-container {
  transform: scale(1.1);
}
</style>
