<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ `Swensa.ru${content ? ` ${content}` : ''}` }}</template>
  </metainfo>
  <router-view v-slot="{ Component }" :key="$route.fullPath">
    <transition name="route" mode="out-in">
      <component :is="Component"/>
    </transition>
  </router-view>
</template>

<script>
  import st from '@/services/storage.service'
  import router from '@/router';
  import isKiosk from './helpers/isKiosk';

  export default {
    name: 'App',
    metaInfo() {
      return {
        title: '- современный бренд аксессуаров для ванной комнаты'
      };
    },
    async created() {
      if (isKiosk()) {
        setTimeout(() => {
          router.push({ path: '/' })
          st.clearRecently()
        }, 10 * 60 * 1000);
      }
    }
  }
</script>

<style lang="scss">
  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: flex-start;
    background: #fff;
  }
  .route {
    &-enter-from {
      opacity: 0;
      transform: translateY(100px);
    }

    &-enter-active {
      transition: all .3s ease-in-out;
    }

    &-live-to {
      opacity: 0;
      transform: translateY(-100px);
    }

    &-leave-active {
      transition: all .3s ease-in;
    }
  }
</style>
