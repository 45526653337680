<script>
import { mapActions } from 'vuex';
import CategoryPage from '@/views/CategoryPage';
import { searchProducts } from '../services/api.service';

export default {
  name: 'SearchPage',
  mixins: [
    CategoryPage
  ],
  metaInfo() {
    return {
      title: `/ Поиск`
    };
  },
  computed: {
    crumbs() {
      return ['Поиск'];
    },
  },
  methods: {
    ...mapActions('category', [
      'fetchFilters',
    ]),
    loadData() {
      this.fetchFilters(this.$route.query.term);
    },
    loadProducts($state) {
      this.isProductsFetching = true;

      searchProducts(this.$route.query.term, this.appliedFilter, this.page, this.perPage, false)
          .then((products) => {
            this.products = [...this.products, ...products];
            products.length < this.perPage ? $state.complete() : $state.loaded();
            this.page += 1;
          })
          .catch(() => {
            $state.complete();
          })
          .finally(() => {
            this.isProductsFetching = false;
          });
    }
  },
}
</script>
