<template>
  <DotsLoader v-if="isFetching" />
  <CatalogHeader :isMobileHidden="true" />
  <BreadCrumbs
    :crumbs="crumbs"
    @selected="selected"
    class="mobileHidden"
    :collectionUrl="product && product.collection ? `/collection/${product.collection.slug}` : ''"
    :collectionName="product && product.collection ? product.collection.title : ''"
  />
  <div class="ProductPage" v-if="product">
    <div class="header showMobile" v-if="product">
      <SvgIcon
          name="arr-back"
          :size="32"
          :style="{marginLeft: '-10px'}"
          @click="$router.push(`/category/${product.category.slug}`)"
      />
      <div class="medium-fs bold">{{ product.category.title }}</div>
    </div>
    <div class="common-info">
      <div class="gallery">
        <ThumbGallery :images="getImages()" />
      </div>
      <div class="details">
        <small class="sku">
          <AppFavourite :product="product" />
          <span class="muted">Артикул:</span>&nbsp;{{product.sku}}
        </small>

        <h1 class="name">{{product.title}}</h1>

        <div><b>Цвет</b></div>
        <div class="colors">
          <div class="item" v-if="!hasGroupedProducts(getColorId())">
            <div class="name" :class="{ muted: false }">
              <GroupProduct
                :product="product"
                :image="getFirstImage"
                :title="getColor()"
                :active="true"
              />
            </div>
          </div>
          <div class="item" v-for="(color, index) in Object.keys(getGroupedProducts(getColorId()))" :key="`color${index}`">
            <div class="name" :class="{ muted: false }" v-if="getGroupedProductImage(getColorId(), color)">
              <GroupProduct
                :product="getGroupedProduct(getColorId(), color)"
                :image="getGroupedProductImage(getColorId(), color)"
                :title="color"
                :active="isActiveGroupedProduct(getColorId(), color)"
              />
            </div>
          </div>
        </div>

        <div><b>Характеристики</b></div>
        <dl class="сharacteristics">
          <template v-if="product.collection">
            <dt class="muted">Коллекция</dt>
            <dd>
              <router-link :to="`/collection/${product.collection.slug}`">
                {{ product.collection.title }}
              </router-link>
            </dd>
          </template>
          <template v-for="(productProperty, index) in getProperties()" :key="`${productProperty.property.title}${index}`">
            <dt class="muted">{{ productProperty.property.title }}</dt>
            <dd v-if="!hasGroupedProducts(productProperty.property.id)" v-html="productProperty.value" />
            <dd v-if="hasGroupedProducts(productProperty.property.id)" class="property-product">
              <div class="item" v-for="(propValue, index) in Object.keys(getGroupedProducts(productProperty.property.id))" :key="`propValue${index}`">
                <div class="name" :class="{ muted: false }" v-if="getGroupedProductImage(productProperty.property.id, propValue)">
                  <GroupProduct
                    :product="getGroupedProduct(productProperty.property.id, propValue)"
                    :image="getGroupedProductImage(productProperty.property.id, propValue)"
                    :title="propValue"
                    :active="isActiveGroupedProduct(productProperty.property.id, propValue)"
                  />
                </div>
              </div>
            </dd>
          </template>
        </dl>
      </div>
    </div>

    <template v-if="hasTabs">
      <tabs v-model="selectedTab">
        <tab
            v-if="hasDescriptionTab"
            class="tab"
            :val="1"
            label="Описание"
            :indicator="true"
        />
        <tab
            v-if="hasRecommendedTab"
            class="tab"
            :val="2"
            label="Рекомендуем посмотреть"
            :indicator="true"
        />
        <tab
            v-if="hasCollectionTab"
            class="tab"
            :val="3"
            :label="`Все товары коллекции ${product.collection.title}`"
            :indicator="true"
        />
      </tabs>
      <tab-panels v-model="selectedTab" :animate="true">
        <tab-panel :val="1">
          <div class="content" v-if="hasDescriptionTab">
            {{ product.description }}
          </div>
        </tab-panel>
        <tab-panel :val="2">
          <div class="content" v-if="hasRecommendedTab">
            <ProductCarousel :items="product.recommendedProducts" />
          </div>
        </tab-panel>
        <tab-panel :val="3">
          <div class="content" v-if="hasCollectionTab">
            <ProductCarousel :items="collection" />
          </div>
        </tab-panel>
      </tab-panels>
    </template>
  </div>
  <CatalogFooter />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getImageUrl, getThumbImageUrl } from '../services/api.service';

import SvgIcon from '@/components/SvgIcon';
import DotsLoader from '@/components/DotsLoader';
import CatalogHeader from '@/components/CatalogHeader';
import CatalogFooter from '@/components/CatalogFooter';
import BreadCrumbs from '@/components/BreadCrumbs';
import ProductCarousel from '@/components/ProductCarousel';
import ThumbGallery from '@/components/ThumbGallery';
import GroupProduct from '@/components/GroupProduct';
import AppFavourite from '@/components/AppFavourite';
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';
import { reactive, toRefs } from 'vue';
import storage from '@/services/storage.service'

const state = reactive({selectedTab: 1});

export default {
  name: 'ProductPage',
  components: {
    CatalogHeader,
    CatalogFooter,
    BreadCrumbs,
    ProductCarousel,
    Tabs,
    Tab,
    TabPanels,
    TabPanel,
    ThumbGallery,
    DotsLoader,
    GroupProduct,
    SvgIcon,
    AppFavourite,
  },
  data() {
    return {
      ...toRefs(state),
    }
  },
  mounted() {
    this.fetchProduct({ categorySlug: this.$route.params.category, sku: this.$route.params.id });
  },
  computed: {
    ...mapState({
      isFetching: state => state.product.isFetching,
      product: state => state.product.product,
      collection: state => state.product.collectionProducts,
      groupProducts: state => state.product.groupProducts,
    }),
    ...mapGetters('product', {
      crumbs: 'getCrumbs',
    }),
    hasTabs() {
      return this.hasCollectionTab || this.hasRecommendedTab || this.hasDescriptionTab;
    },
    hasCollectionTab() {
      return this.product && this.product.collection && this.collection.length > 0;
    },
    hasRecommendedTab() {
      return this.product && this.product.recommendedProducts && this.product.recommendedProducts.length > 0;
    },
    hasDescriptionTab() {
      return this.product && !!this.product.description;
    },
    getFirstImage() {
      return this.product && this.product.images ? getThumbImageUrl(this.product.images[0]) : null
    },
  },
  watch: {
    product(newProduct, oldProduct) {
      if (!oldProduct && !!newProduct) {
        storage.addRecently(newProduct);
        this.selectTab();
      }
    },
  },
  methods: {
    ...mapActions('product', [
      'fetchProduct',
    ]),
    selectTab() {
      if (this.hasDescriptionTab) {
        this.selectedTab = 1;
      } else if (this.hasRecommendedTab) {
        this.selectedTab = 2;
      } else if (this.hasCollectionTab) {
        this.selectedTab = 3;
      }
    },
    selected(crumb) {
      if (crumb.id) {
        this.$router.push(`/category/${crumb.id}`)
      }
    },
    getImages() {
      const images = [];

      this.product.images.map((image, index) => {
        images.push({id: index, big: getImageUrl(image), small: getThumbImageUrl(image)})
      });

      this.getColor();

      return images;
    },
    hasGroupedProducts(propertyId) {
      return !!this.groupProducts[propertyId];
    },
    getGroupedProducts(propertyId) {
      return this.groupProducts[propertyId] || {};
    },
    getGroupedProduct(propertyId, value) {
      return this.getGroupedProducts(propertyId)[value] || {};
    },
    isActiveGroupedProduct(propertyId, value) {
      const groupedProduct = this.getGroupedProduct(propertyId, value);

      if (!this.product || !groupedProduct) {
        return false;
      }

      return this.product.sku === groupedProduct.sku;
    },
    getGroupedProductImage(propertyId, value) {
      const groupedProduct = this.getGroupedProduct(propertyId, value);

      return groupedProduct.images ? getThumbImageUrl(groupedProduct.images[0]) : null;
    },
    getColorId() {
      let colorId = null;

      this.product.properties.map((productProperty) => {
        if (productProperty.property.title === 'Цвет') {
          colorId = productProperty.property.id;
        }
      });

      return colorId;
    },
    getColor() {
      let color = null;

      this.product.properties.map((productProperty) => {
        if (productProperty.property.title === 'Цвет') {
          color = productProperty.value;
        }
      });

      return color;
    },
    getProperties() {
      return this.product.properties.filter((productProperty) => {
        return productProperty.property.title !== 'Цвет' && productProperty.property.useFor !== 'filter';
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixins";
@import "../styles/variables";

.ProductPage {
  @include side-padding;
  
  margin-top: 16px;
  @media screen and (min-width: $BreakpointSM) {
    margin-top: 0;
  }

  width: 100%;
  .common-info {
    width: 100%;
    display: grid;
    gap: 20px;

    grid-template-columns: 1fr;

    @media screen and (min-width: $BreakpointSM) {
      grid-template-columns: 1fr 1fr;
    }

    .gallery {
      flex-basis: 45%;
      @include media-rule(mobile) {
        flex-basis: unset;
      }
    }

    .details {
      flex-basis: 50%;
      @include media-rule(mobile) {
        flex-basis: unset;
      }
      > .name {
        font-weight: 400;
        margin: 16px 0;
      }

      .sku {
        display: flex;
        align-items: center;
      }

      .colors, .property-product {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 20px;
        margin: 16px 0;

        @include media-rule(fullhd){
          gap: 32px;
          margin: 28px 0;
        }
        @include media-rule(twok){
          gap: 40px;
          margin: 24px 0;
        }
        > .item {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          @include media-rule(mobile){
            width: 64px;
          }
          @include media-rule(desktop){
            width: 60px;
          }
          @include media-rule(fullhd){
            width: 72px;
          }
          @include media-rule(twok){
            width: 100px;
          }

          &.disabled {
            opacity: .5;
          }
          .image {
            border: 1px solid $light-gray;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;

            @include media-rule(mobile){
              width: 64px;
              height: 64px;
              padding: 8px;
            }
            @include media-rule(desktop){
              width: 60px;
              height: 60px;
            }
            @include media-rule(fullhd){
              width: 72px;
              height: 72px;
              padding: 16px;
              margin-bottom: 16px;
            }
            @include media-rule(twok){
              width: 100px;
              height: 100px;
              padding: 10px;
              margin-bottom: 16px;
            }

            > img {
              width: 100%;
              height: 100%;
            }
          }
          &.selected .image {
            border-color: black;
          }
        }
      }
      dl.сharacteristics {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0;

        dt {
          margin: 4px 0;
          padding-right: 10px;
          overflow: hidden;
          width: 100px;
          @include media-rule(fullhd) {
            width: 150px;
          }
          @include media-rule(twok) {
            width: 150px;
          }
        }
        dd {
          margin: 4px 0;
          width: calc(100% - 100px);
          @include media-rule(fullhd) {
            width: calc(100% - 150px);
          }
          @include media-rule(twok) {
            width: calc(100% - 150px);
          }
        }
      }
    }
  }

  .tabs {
    justify-content: center;
    border-bottom: 1px solid $gray;
    flex-wrap: nowrap;
    
    @media screen and (min-width: $BreakpointSM) {
      margin: 0 124px;
      gap: 120px;
    }

    > .tab {
      padding: 15px 0;
      &.active {
        font-weight: bold;
      }
    }
  }
  .tab-panel {
    > .content {
      display: flex;
      justify-content: center;
      margin: 40px 0;
    }
  }
}

.showMobile {
  display: none;

  @include media-rule(mobile) {
    display: flex;
    align-items: center;
    margin: 22px 0;
  }
}

@include media-rule(mobile) {
  .mobileHidden {
    display: none !important;
  }
}
</style>
