<template>
<div class="CheckToggle">
  <label>
    <div class="title"><b>{{ title }}</b></div>
    <div :class="{'toggler': true, 'checked': checked}" @click="toggle"/>
  </label>
</div>
</template>

<script>
export default {
  name: 'CheckToggle',
  props: {
    trueValue: {
      type: Object,
      default: () => ({})
    },
    title: String,
    variant: String,
    modelValue: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    toggle() {
      this.checked = !this.checked;
      this.updateValue();
    },
    updateValue() {
      const newValue = this.checked ? [this.trueValue.title] : [];

      this.$emit('update:modelValue', newValue);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";

.CheckToggle {
  margin-bottom: 10px;
  > label {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    .toggler {
      position: relative;
      display: inline-block;
      user-select: none;
      transition: 0.4s ease;
      background: $gray;
      overflow: hidden;
      margin-left: 10px;
      width: 38px;
      height: 22px;
      border-radius: 60px;
      @include media-rule(desktop) {
        height: 12px;
        width: 20px;
      }
      @include media-rule(fullhd) {
        height: 16px;
        width: 28px;
      }
      &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
        background: white;
        top: 3px;
        left: 3px;
        height: 16px;
        width: 16px;
        @include media-rule(desktop) {
          top: 2px;
          left: 2px;
          height: 8px;
          width: 8px;
        }
        @include media-rule(fullhd) {
          top: 2px;
          left: 2px;
          height: 12px;
          width: 12px;
        }
      }

      &.checked {
        background: $bg-secondary;
        &::after {
          left: 19px;
          @include media-rule(desktop) {
            left: 9px;
          }
          @include media-rule(fullhd) {
            left: 14px;
          }
        }
      }
    }
  }
}
</style>
