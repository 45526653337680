<template>
  <div class="menu-view" v-bind:class="{ offset: withOffset }" v-if="menuToggled">
    <div class="medium-fs bold menu-header">
      Меню
      <SvgIcon name="close" class="close" @click="toggleMenu" />
    </div>

    <div class="menu-body">
      <ul>
        <li>
          <router-link to="/catalog">Категории</router-link>
        </li>
        <li>
          <router-link to="/favourites">Избранное</router-link>
        </li>
        <li>
          <router-link to="/recently">Просмотренные</router-link>
        </li>
      </ul>
      <div class="contacts">
        <p>Телефон: <a href="tel:+74957273581">+7 495 727 35 81</a></p>
        <p>Электронный адрес: <a href="mailto:pochta@hydroforce.ru">pochta@hydroforce.ru</a></p>
        <div class="social">
          <a href="https://vk.com/hydroforce_swensa" target="_blank">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M4.96016 4.96016C2.5 7.42047 2.5 11.3806 2.5 19.3V20.7C2.5 28.6195 2.5 32.5796 4.96016 35.0398C7.42047 37.5 11.3806 37.5 19.3 37.5H20.7C28.6195 37.5 32.5796 37.5 35.0398 35.0398C37.5 32.5796 37.5 28.6195 37.5 20.7V19.3C37.5 11.3805 37.5 7.42039 35.0398 4.96016C32.5796 2.5 28.6195 2.5 20.7 2.5H19.3C11.3805 2.5 7.42039 2.5 4.96016 4.96016ZM8.40625 13.1459H12.4021C12.5334 19.8251 15.4791 22.6542 17.8125 23.2376V13.1459H21.575V18.9062C23.8791 18.6584 26.3 16.0334 27.1166 13.1459H30.8791C30.5713 14.6434 29.9579 16.0613 29.0771 17.3109C28.1963 18.5605 27.0672 19.615 25.7604 20.4084C27.2195 21.1327 28.5083 22.1586 29.5416 23.4181C30.5748 24.6776 31.3289 26.142 31.7541 27.7146H27.6125C27.2309 26.3486 26.4543 25.1258 25.3801 24.1995C24.306 23.2733 22.9823 22.685 21.575 22.5084V27.7146H21.1229C13.1459 27.7146 8.59586 22.2459 8.40625 13.1459V13.1459Z"
                  fill="white"/>
            </svg>
          </a>
          <a href="https://zen.yandex.ru/id/6290a75e90e70861ddce3017" target="_blank">
            <svg width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5822 1.66561C8.387 2.27864 3.25311 6.84138 1.93673 12.9141C1.75161 13.7534 1.60352 14.9218 1.60352 15.5225V15.7241L2.70187 15.6953C6.75798 15.5925 8.90122 15.3045 10.5837 14.6379C12.5007 13.8768 13.8788 12.4985 14.6357 10.5854C15.1376 9.32229 15.4379 7.69304 15.5901 5.38492C15.6353 4.66904 15.7135 2.61189 15.7135 1.99886V1.59978L15.4379 1.60389C15.2816 1.60801 14.899 1.63681 14.5822 1.66561Z" fill="white"/>
              <path d="M16.5771 2.24572C16.5771 3.11384 16.6471 4.80481 16.7211 5.81692C16.9474 8.85738 17.4739 10.7417 18.5065 12.1899C18.9878 12.8688 19.8311 13.6505 20.5469 14.0743C22.3569 15.1522 24.5989 15.5595 29.4242 15.6953L30.3992 15.7241V15.5431C30.3992 14.9383 30.247 13.7369 30.066 12.9141C28.8319 7.22401 24.2369 2.82172 18.4694 1.80549C18.0828 1.73966 16.8487 1.59978 16.6306 1.59978C16.5895 1.59978 16.5771 1.73144 16.5771 2.24572Z" fill="white"/>
              <path d="M1.62429 16.9092C1.91636 20.9247 3.68114 24.4054 6.74585 27.0303C8.61347 28.6349 11.1886 29.8157 13.6774 30.2148C14.167 30.297 15.1954 30.3999 15.5039 30.3999H15.7261L15.6973 29.4454C15.615 26.5983 15.471 24.9608 15.1748 23.5373C14.7881 21.6817 14.1587 20.3857 13.1262 19.316C11.2668 17.3988 8.70397 16.7158 2.90777 16.5924L1.59961 16.5636L1.62429 16.9092Z" fill="white"/>
              <path d="M28.651 16.6047C23.188 16.7857 20.728 17.5304 18.9509 19.53C17.4165 21.258 16.8036 23.5743 16.6184 28.3633C16.5608 29.8527 16.565 30.3999 16.6308 30.3999C16.8406 30.3999 18.0418 30.2641 18.4408 30.1942C20.041 29.9144 21.6372 29.3549 23.0029 28.5896C26.6271 26.5572 29.231 23.0353 30.0867 19.0033C30.2266 18.345 30.3911 17.0737 30.3993 16.6047C30.3993 16.58 29.4491 16.5759 28.651 16.6047Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="logo">
        <svg viewBox="0 0 96 100" xmlns="http://www.w3.org/2000/svg">
          <path d="M79.7593 26.727V26.1951L51.3833 26.1868C58.2756 22.8138 63.0215 15.7474 63.0215 7.57369V0.155762H39.2764L39.3074 4.45949C39.4003 17.2595 29.0573 27.6914 16.2402 27.7621V28.2961L44.6279 28.2975C37.728 31.6677 32.9759 38.7383 32.9759 46.9175V54.3347H56.721L56.69 50.031C56.5971 37.2303 66.9408 26.7977 79.7586 26.7284L79.7593 26.727Z" fill="#477762"/>
          <path d="M8.27684 85.2347C5.78761 85.2347 3.904 84.6931 2.62463 83.6093C1.34526 82.5255 0.643984 81.0133 0.521484 79.0729H4.59633C4.70163 80.0345 5.02095 80.7861 5.55569 81.3277C6.09042 81.8699 6.99678 82.1404 8.27684 82.1404C9.20591 82.1404 9.95468 81.9091 10.5245 81.4458C11.0937 80.9824 11.3793 80.3839 11.3793 79.6494C11.3793 78.8978 11.1336 78.3563 10.6429 78.024C10.1522 77.6925 9.21417 77.4207 7.8302 77.2113C5.37607 76.897 3.63216 76.3156 2.59848 75.4679C1.56411 74.6202 1.04727 73.305 1.04727 71.5218C1.04727 69.8964 1.67354 68.5586 2.92675 67.5098C4.17997 66.4609 5.83166 65.9365 7.8825 65.9365C10.0029 65.9365 11.6635 66.4088 12.8644 67.3526C14.0646 68.2964 14.7528 69.6953 14.9283 71.5479H11.0111C10.7482 69.8874 9.70486 69.0569 7.8825 69.0569C6.97132 69.0569 6.27417 69.2491 5.79243 69.6335C5.31 70.0185 5.06913 70.534 5.06913 71.1806C5.06913 71.8801 5.30105 72.3997 5.76559 72.7408C6.23012 73.082 7.16333 73.3572 8.5652 73.5666C10.9835 73.8809 12.7364 74.4452 13.8231 75.2579C14.9097 76.0706 15.4527 77.4035 15.4527 79.2568C15.4527 80.9701 14.7996 82.395 13.4941 83.531C12.1893 84.6663 10.4495 85.2347 8.27684 85.2347Z" fill="white"/>
          <path d="M20.8432 84.9455L16.6631 71.2319H20.6588L23.104 80.462L25.7591 71.2319H28.8876L31.306 80.462L33.9088 71.2319H37.5631L33.2254 84.9455H29.4926L27.1527 76.3449L24.629 84.9455H20.8432Z" fill="white"/>
          <path d="M45.45 85.2342C43.329 85.2342 41.603 84.6096 40.2713 83.3597C38.9389 82.1097 38.2734 80.4095 38.2734 78.2597V78.0497C38.2734 75.917 38.9437 74.1955 40.2844 72.8845C41.625 71.5735 43.3118 70.918 45.3454 70.918C47.2731 70.918 48.8724 71.5042 50.1436 72.6745C51.414 73.8455 52.0499 75.5848 52.0499 77.8925V78.9413H42.1377C42.19 80.078 42.5141 80.96 43.1101 81.5894C43.7054 82.2189 44.5209 82.5332 45.5553 82.5332C47.2029 82.5332 48.1581 81.8956 48.421 80.6189H52.0224C51.7946 82.1049 51.1112 83.2457 49.9722 84.0406C48.8325 84.8368 47.3254 85.2342 45.45 85.2342ZM42.19 76.6075H48.3418C48.2193 74.5621 47.2201 73.5393 45.3447 73.5393C44.4858 73.5393 43.7804 73.8063 43.2285 74.339C42.6766 74.8723 42.3304 75.6287 42.19 76.6075Z" fill="white"/>
          <path d="M54.5459 84.9454V71.2319H58.3578V73.4084C58.726 72.674 59.291 72.0754 60.0536 71.6121C60.8161 71.1488 61.7404 70.9175 62.827 70.9175C64.2468 70.9175 65.3727 71.3588 66.2054 72.2415C67.0374 73.1243 67.4538 74.4483 67.4538 76.2137V84.9454H63.6419V76.7642C63.6419 75.8204 63.4485 75.1251 63.0638 74.6796C62.6784 74.2342 62.0645 74.0111 61.2235 74.0111C60.3646 74.0111 59.6723 74.2692 59.1465 74.7847C58.6207 75.3002 58.3578 76.0476 58.3578 77.0264V84.9454H54.5459Z" fill="white"/>
          <path d="M75.7091 85.2345C73.8509 85.2345 72.405 84.8412 71.3713 84.0546C70.337 83.268 69.785 82.132 69.7148 80.6459H73.2639C73.3513 81.2925 73.5791 81.7778 73.9473 82.1011C74.3155 82.4251 74.8936 82.5864 75.6822 82.5864C77.1192 82.5864 77.8377 82.0881 77.8377 81.0921C77.8377 80.6377 77.6271 80.2883 77.2066 80.0433C76.7861 79.7989 76.0669 79.6067 75.0512 79.4667C73.193 79.1873 71.8834 78.7322 71.1208 78.1035C70.3583 77.4741 69.977 76.5042 69.977 75.1932C69.977 73.83 70.507 72.777 71.5675 72.0337C72.628 71.291 73.9115 70.9189 75.4187 70.9189C78.8886 70.9189 80.7818 72.335 81.097 75.1664H77.6003C77.3897 74.0654 76.6712 73.5142 75.4441 73.5142C74.8303 73.5142 74.3533 73.6453 74.0113 73.9075C73.6693 74.1697 73.4986 74.5109 73.4986 74.9303C73.4986 75.3675 73.6865 75.6908 74.0636 75.9002C74.44 76.1102 75.1372 76.2845 76.1537 76.4246C78.0111 76.6868 79.3648 77.124 80.2154 77.7356C81.0654 78.3479 81.4907 79.3438 81.4907 80.7249C81.4907 82.1931 80.9869 83.3119 79.9794 84.0814C78.9732 84.8501 77.5493 85.2345 75.7091 85.2345Z" fill="white"/>
          <path d="M87.6182 85.2344C86.2686 85.2344 85.1641 84.8933 84.3059 84.2117C83.447 83.5301 83.0176 82.5074 83.0176 81.1442C83.0176 78.2071 85.4008 76.7388 90.1687 76.7388H91.7199V76.1884C91.7199 75.3324 91.5534 74.6982 91.2203 74.287C90.8872 73.8766 90.3091 73.6706 89.4853 73.6706C88.1006 73.6706 87.3202 74.3172 87.1454 75.6111H83.5434C83.6487 74.0557 84.2618 72.884 85.3836 72.0974C86.5047 71.3108 87.9506 70.9175 89.7214 70.9175C93.5595 70.9175 95.4789 72.6136 95.4789 76.0044V84.9454H91.7722V83.2933C90.8431 84.5878 89.4585 85.2344 87.6182 85.2344ZM88.7489 82.5856C89.6071 82.5856 90.3173 82.3673 90.8782 81.9301C91.4391 81.4935 91.7192 80.8991 91.7192 80.1468V78.993H90.2472C89.0903 78.993 88.2183 79.1419 87.6313 79.4385C87.0436 79.7357 86.7504 80.234 86.7504 80.9327C86.7511 82.0351 87.4166 82.5856 88.7489 82.5856Z" fill="white"/>
          <path d="M19.7968 91.5376V97.9424H18.6695V95.0636H15.7288V97.9424H14.6016V91.5376H15.7288V94.0835H18.6695V91.5376H19.7968Z" fill="white"/>
          <path d="M23.1515 93.166C23.8611 93.166 24.4261 93.3877 24.8473 93.8318C25.2677 94.2759 25.4783 94.8724 25.4783 95.622C25.4783 96.3777 25.2691 96.9776 24.8514 97.421C24.4336 97.8651 23.8666 98.0868 23.1515 98.0868C22.4241 98.0868 21.8467 97.8651 21.42 97.421C20.9926 96.9776 20.7793 96.3777 20.7793 95.622C20.7793 94.8724 20.994 94.2759 21.4241 93.8318C21.8543 93.3877 22.4296 93.166 23.1515 93.166ZM24.0937 94.4118C23.8624 94.1208 23.5417 93.9753 23.1329 93.9753C22.7242 93.9753 22.4021 94.1222 22.1681 94.416C21.9334 94.7097 21.8164 95.112 21.8164 95.6213C21.8164 96.1429 21.9334 96.5507 22.1681 96.8444C22.4028 97.1382 22.7276 97.2851 23.1419 97.2851C23.5445 97.2851 23.8618 97.1368 24.0937 96.8396C24.3249 96.5424 24.4412 96.1333 24.4412 95.6117C24.4412 95.103 24.3256 94.7029 24.0937 94.4118Z" fill="white"/>
          <path d="M27.3273 93.3009V93.9578C27.712 93.4361 28.1993 93.1753 28.7884 93.1753C29.0953 93.1753 29.361 93.2446 29.5867 93.3819C29.8124 93.5199 29.979 93.7148 30.087 93.9667C30.46 93.4389 30.9617 93.1753 31.5935 93.1753C32.0326 93.1753 32.3959 93.3057 32.685 93.5665C32.974 93.8274 33.1179 94.1891 33.1179 94.6504V97.943H32.0897V95.091C32.0897 94.7616 32.0326 94.5035 31.9183 94.3175C31.8041 94.1315 31.5845 94.0388 31.2597 94.0388C30.9287 94.0388 30.6761 94.1424 30.502 94.349C30.3272 94.5556 30.2405 94.8453 30.2405 95.2173V97.943H29.2123V95.0375C29.2123 94.708 29.1628 94.4589 29.0637 94.2907C28.9646 94.1225 28.7581 94.0388 28.4457 94.0388C28.0967 94.0388 27.8325 94.1514 27.6522 94.3765C27.4718 94.6016 27.3817 94.8995 27.3817 95.2716V97.943H26.3535V93.3009H27.3273Z" fill="white"/>
          <path d="M38.3578 96.4942C38.2436 96.998 37.9958 97.3907 37.6139 97.6728C37.2319 97.9549 36.7763 98.0956 36.2471 98.0956C35.5437 98.0956 34.9856 97.8691 34.5741 97.4167C34.1618 96.9637 33.9561 96.3837 33.9561 95.676C33.9561 94.9505 34.168 94.3506 34.592 93.8769C35.0159 93.4033 35.5554 93.1665 36.2106 93.1665C36.8899 93.1665 37.428 93.3992 37.8251 93.8639C38.2222 94.3286 38.4204 94.9628 38.4204 95.7666V95.8744H34.9842C34.9959 96.3418 35.1136 96.7036 35.3359 96.9582C35.5582 97.2129 35.8679 97.3406 36.265 97.3406C36.5478 97.3406 36.7777 97.2733 36.9552 97.1381C37.1328 97.0028 37.2725 96.7887 37.3744 96.4949H38.3578V96.4942ZM37.3929 95.19C37.3627 94.8002 37.2408 94.4926 37.0275 94.2682C36.8142 94.0431 36.5389 93.9312 36.2023 93.9312C35.8658 93.9312 35.5933 94.0389 35.3861 94.2551C35.179 94.4714 35.051 94.783 35.0028 95.1907H37.3929V95.19Z" fill="white"/>
          <path d="M40.532 96.728V97.9422H39.3057V96.728H40.532Z" fill="white"/>
          <path d="M49.5331 91.5376V97.9424H48.4059V95.0636H45.4652V97.9424H44.3379V91.5376H45.4652V94.0835H48.4059V91.5376H49.5331Z" fill="white"/>
          <path d="M50.6878 94.7308C50.7958 93.6813 51.4757 93.1562 52.7262 93.1562C53.3091 93.1562 53.7736 93.2702 54.1198 93.4981C54.4653 93.726 54.6387 94.0801 54.6387 94.5592V96.9163C54.6387 97.0481 54.6552 97.1381 54.6882 97.1861C54.7213 97.2342 54.786 97.2582 54.8823 97.2582C54.9546 97.2582 55.0324 97.252 55.117 97.2403V97.9597C54.852 98.0256 54.6298 98.0585 54.4494 98.0585C54.0104 98.0585 53.7578 97.8725 53.6917 97.5012C53.3008 97.8732 52.7565 98.0585 52.0593 98.0585C51.5783 98.0585 51.1963 97.9343 50.9142 97.6851C50.6313 97.4366 50.4902 97.0989 50.4902 96.6734C50.4902 96.5416 50.5054 96.4187 50.5357 96.3048C50.5659 96.1908 50.6003 96.092 50.6396 96.0076C50.6788 95.9238 50.7414 95.8456 50.8288 95.7735C50.9162 95.7014 50.9878 95.6417 51.0456 95.5937C51.1027 95.5456 51.1956 95.501 51.325 95.4584C51.4544 95.4166 51.5507 95.385 51.6134 95.3637C51.6767 95.3431 51.7861 95.3205 51.9423 95.2964C52.0986 95.2724 52.2025 95.2559 52.2534 95.247C52.3043 95.2381 52.411 95.2244 52.5734 95.2065C52.9402 95.1585 53.19 95.118 53.3222 95.085C53.4543 95.0521 53.5445 95.0027 53.5926 94.9368C53.6346 94.8887 53.656 94.7782 53.656 94.6039C53.656 94.1357 53.3373 93.9024 52.7 93.9024C52.3635 93.9024 52.1165 93.9655 51.9602 94.0911C51.804 94.2167 51.7049 94.4302 51.6622 94.7302H50.6878V94.7308ZM53.6456 95.6129C53.5672 95.6547 53.477 95.6925 53.3752 95.7254C53.2726 95.7584 53.19 95.7797 53.1274 95.7886C53.0641 95.7975 52.965 95.8112 52.8294 95.8291C52.6939 95.8469 52.6051 95.8593 52.5631 95.8648C52.4372 95.8826 52.3374 95.8991 52.2651 95.9142C52.1928 95.9293 52.1041 95.9547 51.9988 95.9904C51.8935 96.0261 51.8123 96.07 51.7551 96.1208C51.698 96.1716 51.6485 96.2409 51.6065 96.3274C51.5645 96.4146 51.5432 96.5176 51.5432 96.6377C51.5432 96.8594 51.6168 97.0303 51.7641 97.1504C51.9114 97.2705 52.1171 97.3302 52.3821 97.3302C52.8391 97.3302 53.1907 97.2074 53.4371 96.9617C53.5754 96.8237 53.6443 96.5567 53.6443 96.1613V95.6129H53.6456Z" fill="white"/>
          <path d="M58.3811 93.2102V94.1911C58.2008 94.1733 58.0804 94.1643 58.0205 94.1643C57.1245 94.1643 56.6765 94.743 56.6765 95.9002V97.9423H55.6572V93.3002H56.6221V94.2268C56.9524 93.537 57.4727 93.1924 58.1823 93.1924L58.3811 93.2102Z" fill="white"/>
          <path d="M59.9055 93.3009V93.9578C60.2902 93.4361 60.7774 93.1753 61.3665 93.1753C61.6734 93.1753 61.9391 93.2446 62.1648 93.3819C62.3906 93.5199 62.5571 93.7148 62.6651 93.9667C63.0382 93.4389 63.5399 93.1753 64.1716 93.1753C64.6107 93.1753 64.9741 93.3057 65.2631 93.5665C65.5515 93.8274 65.696 94.1891 65.696 94.6504V97.943H64.6678V95.091C64.6678 94.7616 64.6107 94.5035 64.4965 94.3175C64.3822 94.1315 64.1627 94.0388 63.8378 94.0388C63.5068 94.0388 63.2542 94.1424 63.0801 94.349C62.9053 94.5556 62.8186 94.8453 62.8186 95.2173V97.943H61.7904V95.0375C61.7904 94.708 61.7409 94.4589 61.6418 94.2907C61.5427 94.1225 61.3362 94.0388 61.0238 94.0388C60.6749 94.0388 60.4106 94.1514 60.2303 94.3765C60.05 94.6016 59.9598 94.8995 59.9598 95.2716V97.943H58.9316V93.3009H59.9055Z" fill="white"/>
          <path d="M68.9256 93.166C69.6352 93.166 70.2002 93.3877 70.6214 93.8318C71.0419 94.2759 71.2524 94.8724 71.2524 95.622C71.2524 96.3777 71.0432 96.9776 70.6255 97.421C70.2078 97.8651 69.6407 98.0868 68.925 98.0868C68.1975 98.0868 67.6201 97.8651 67.1934 97.421C66.7661 96.9776 66.5527 96.3777 66.5527 95.622C66.5527 94.8724 66.7675 94.2759 67.1976 93.8318C67.6284 93.3877 68.2037 93.166 68.9256 93.166ZM69.8678 94.4118C69.6366 94.1208 69.3159 93.9753 68.9071 93.9753C68.4983 93.9753 68.1762 94.1222 67.9422 94.416C67.7075 94.7097 67.5905 95.112 67.5905 95.6213C67.5905 96.1429 67.7075 96.5507 67.9422 96.8444C68.1769 97.1382 68.5017 97.2851 68.916 97.2851C69.3186 97.2851 69.6359 97.1368 69.8678 96.8396C70.099 96.5424 70.2153 96.1333 70.2153 95.6117C70.2153 95.103 70.0997 94.7029 69.8678 94.4118Z" fill="white"/>
          <path d="M73.1014 93.3011V93.9848C73.4745 93.4452 73.9885 93.1748 74.6437 93.1748C75.1729 93.1748 75.568 93.319 75.8295 93.6066C76.091 93.8942 76.2218 94.3081 76.2218 94.8483V97.9426H75.1936V95.2436C75.1936 95.0576 75.1846 94.9032 75.1667 94.7803C75.1489 94.6574 75.111 94.5332 75.0539 94.4069C74.9968 94.2813 74.9052 94.1866 74.7786 94.1234C74.6527 94.0603 74.4902 94.0287 74.2914 94.0287C73.5336 94.0287 73.1551 94.4996 73.1551 95.4413V97.9419H72.127V93.2997H73.1014V93.3011Z" fill="white"/>
          <path d="M81.3991 93.3008L79.6765 97.853C79.6586 97.8949 79.6256 97.9834 79.5774 98.1186C79.5292 98.2538 79.4962 98.3451 79.4783 98.3932C79.4604 98.4412 79.4253 98.525 79.3744 98.6451C79.3235 98.7652 79.2829 98.8489 79.2526 98.897C79.2223 98.945 79.1776 99.0171 79.117 99.1132C79.0565 99.2093 79.0014 99.2779 78.9505 99.3198C78.8995 99.3617 78.8362 99.4145 78.7612 99.477C78.6862 99.5402 78.6091 99.5848 78.5314 99.6122C78.4529 99.639 78.3648 99.663 78.265 99.6843C78.1659 99.7056 78.0592 99.7159 77.945 99.7159C77.7523 99.7159 77.5149 99.698 77.2327 99.6616V98.7982C77.4495 98.84 77.6298 98.8613 77.7736 98.8613C77.8459 98.8613 77.912 98.851 77.9718 98.8297C78.0317 98.8085 78.0861 98.7728 78.1343 98.722C78.1824 98.6712 78.2244 98.6231 78.2602 98.5778C78.296 98.5325 78.3311 98.4701 78.3641 98.3891C78.3971 98.3081 78.4226 98.2449 78.4405 98.2003C78.4584 98.155 78.4825 98.085 78.5128 97.9889C78.5431 97.8928 78.5609 97.8331 78.5671 97.8091L76.7812 93.3021H77.9085L79.1535 96.7204L80.3262 93.3021H81.3991V93.3008Z" fill="white"/>
        </svg>
        <p>© 2022 Swensa</p>
        <p>Все права защищены</p>
      </div>
    </div>
    
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';

export default {
  name: 'ShowMenu',
  components: {
    SvgIcon,
  },
  props: {
    withOffset: Boolean,
    menuToggled: Boolean,
    onToggleMenu: Boolean,
  },
  methods: {
    toggleMenu() {
      this.onToggleMenu();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixins";

.menu-view {

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 78px auto;

  position: fixed;
  top: 16px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  overflow: hidden;
  background: #FFFFFF;
  text-align: center;
  transition: all .3s ease-in-out;


  &.offset {
    // padding-top: 136px;
  }

  .menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 27px 20px;
  }

  .menu-body {
    height: calc(100vh - 16px - 78px);
    padding: 40px 20px;

    background-color: #3B3F3D;
    color: white;

    display: grid;
    grid-template-rows: 1fr min-content min-content;

    ul {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700px;
      li {
        margin-bottom: 24px;
      }
    }

    .contacts {
      p {
        margin-bottom: 16px;
      }
      a {
        font-weight: 700px;
      }
      .social {
        margin: 16px 0 32px 0;
      }
    }

    .logo {
      font-size: 16px;
      line-height: 20px;

      svg {
        width: 72px;
        margin-bottom: 32px;
      }
    }

    a {
      color: white;
    }
  }
}
</style>
