<template>
  <DotsLoader v-if="isFetching && !isProductsFetching" />
  <CatalogHeader :isMobileHidden="filtersToggled" />
  <div class="sub-header">
    <BreadCrumbs
        v-if="crumbs"
        :class="{mobileHidden: filtersToggled}"
        :crumbs="crumbs"
        @selected="selected"
    />
    <div class="mobile-filters" @click="toggleFilters">
      <SvgIcon name="filters" :size=32 v-if="!filtersToggled"/>
      <BaseButton
          variant="orange-light"
          size="small"
          v-if="getAppliedFilerLength() > 0 && !filtersToggled"
      ><b>{{ getAppliedFilerLength() }}</b></BaseButton>
    </div>
  </div>
  <div :class="{'CategoryPage': true, 'filters-toggled': filtersToggled}">
    <div class="sidebar" v-if="hasFilters" :class="{showMobile: filtersToggled}">
      <AppFilters
          ref="filter"
          :filters="filters"
          :onApplyFilter="onApplyFilter"
          :onClearFilter="onClearFilter"
          :onToggleFilter="onToggleFilter"
      />
    </div>
    <div class="content" :class="{mobileHidden: filtersToggled}">
      <div class="products">
        <div v-for="(product, index) in products" :key="index">
          <ProductLink
            :product="product"
            :imageUrl="getImage(product)"
            :title="product.title"
            :vendorCode="product.sku"
          />
        </div>
        <InfiniteLoading @infinite="loadProducts" :identifier="refreshFilter">
          <template #spinner>
            <DotsLoader />
          </template>
          <template #complete><span /></template>
        </InfiniteLoading>
      </div>
      <NoProducts v-if="!isFetching && !isProductsFetching && products.length === 0" />
    </div>
  </div>
  <CatalogFooter />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import InfiniteLoading from "v3-infinite-loading";
import { getThumbImageUrl, getProducts } from '../services/api.service';
import DotsLoader from '@/components/DotsLoader';
import CatalogHeader from '@/components/CatalogHeader';
import CatalogFooter from '@/components/CatalogFooter';
import BreadCrumbs from '@/components/BreadCrumbs';
import ProductLink from '@/components/ProductLink';
import AppFilters from '@/components/AppFilters';
import BaseButton from '@/components/BaseButton';
import SvgIcon from '@/components/SvgIcon';
import NoProducts from '@/components/NoProducts';
import storage from '../services/storage.service';

export default {
  name: 'CategoryPage',
  metaInfo() {
    if (!this.category) {
      return {};
    }

    return {
      title: `/ ${this.category.title}`
    };
  },
  components: {
    BreadCrumbs,
    CatalogHeader,
    CatalogFooter,
    ProductLink,
    DotsLoader,
    InfiniteLoading,
    AppFilters,
    BaseButton,
    SvgIcon,
    NoProducts
  },
  data(){
    return {
      page: 1,
      isProductsFetching: false,
      products: [],
      refreshFilter: false,
      appliedFilter: storage.getFilter(this.$route.path) || {},
      filtersToggled: false,
    }
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapState({
      isFetching: state => state.category.isFetching,
      category: state => state.category.category,
      filters: state => state.category.filters,
      perPage: state => state.product.perPage,
    }),
    ...mapGetters('category', {
      crumbs: 'getCrumbs',
    }),
    hasFilters() {
      let hasFilters = false;

      this.filters.map((filter) => {
        filter.properties.map((property) => {
          if (property.values.length > 1) {
            hasFilters = true;
          }
        });
      });

      return hasFilters;
    }
  },
  methods: {
    ...mapActions('category', [
      'fetchCategory',
    ]),
    ...mapActions('product', [
      'fetchProducts',
    ]),
    loadData() {
      this.fetchCategory(this.$route.params.id);
    },
    getImage(product) {
      return getThumbImageUrl(product.images[0]);
    },
    onClearFilter() {},
    onToggleFilter(filtersToggled) {
      this.filtersToggled = filtersToggled;
    },
    onApplyFilter(appliedFilter) {
      this.refreshFilter = !this.refreshFilter;
      this.products = [];
      this.page = 1;
      this.appliedFilter = appliedFilter;
    },
    toggleFilters() {
      if (!this.$refs.filter) {
        return;
      }

      this.$refs.filter.toggleFilters();
    },
    getAppliedFilerLength() {
      if (!this.$refs.filter) {
        return 0;
      }

      return this.$refs.filter.getAppliedFilerLength();
    },
    loadProducts($state) {
      this.isProductsFetching = true;

      getProducts(this.$route.params.id, this.appliedFilter, this.page, this.perPage)
        .then((products) => {
          this.products = [...this.products, ...products];
          products.length < this.perPage ? $state.complete() : $state.loaded();
          this.page += 1;
        })
        .catch(() => {
          $state.complete();
        })
        .finally(() => {
          this.isProductsFetching = false;
        });
    }
  },
}
</script>

<style lang="scss">
@import "../styles/mixins";
@import "../styles/variables";


.CategoryPage {
  @include side-padding;

  display: flex;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;

  &.filters-toggled {
    @include media-rule(mobile) {
      width: 100%;
      display: block;
    }
  }

  .sidebar {
    min-width: 240px;
    flex-basis: 240px;
    padding-bottom: 40px;

    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 26px;
      .icon {
        display: block;
        @media screen and (min-width: $BreakpointSM) {
          display: none;
        }
      }
    }

    @include media-rule(mobile) {
      display: none;
      width: unset;

      &.showMobile {
          display: block;
        }

    }
  }

  .content {
    width: 100%;
  }

  .products {
    width: 100%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
    gap: 16px;

    @media screen and (min-width: $BreakpointSM) {
      grid-template-columns: repeat(auto-fit, minmax(234px, 1fr));
      padding-left: 32px;
    }
  }
}

.sub-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;

  .mobile-filters {
    @include side-padding;
    margin: 22px 0;
    align-items: center;
    justify-content: center;
    display: flex;

    @media screen and (min-width: $BreakpointSM) {
      display: none;
    }
  }
}

@include media-rule(mobile) {
  .mobileHidden {
    display: none !important;
  }
  .filters-header {
    margin: -20px 0 20px 0;
    @include font-variant(20, 26, 500)
  }
}
</style>
