import _ from 'lodash'
const RECENTLY_KEY = 'recently';
const FAVOURITES_KEY = 'favourites';
const FILTERS_KEY = 'filters';

export default {
    addRecently(product) {
        return this._add(product, RECENTLY_KEY);
    },
    getRecently() {
        return this._get(RECENTLY_KEY);
    },
    clearRecently() {
        return this._clear(RECENTLY_KEY);
    },
    addFavourite(product) {
        return this._add(product, FAVOURITES_KEY);
    },
    getFavourites() {
        return this._get(FAVOURITES_KEY);
    },
    isFavourite(product) {
        return this._has(product, FAVOURITES_KEY);
    },
    removeFavourite(product) {
        return this._remove(product, FAVOURITES_KEY);
    },
    clearFavourites() {
        return this._clear(FAVOURITES_KEY);
    },
    addFilter(url, filter) {
        const filters = this._getFilters();

        filters[url] = filter;

        return localStorage.setItem(FILTERS_KEY, JSON.stringify(filters));
    },
    getFilter(url) {
        const filters = this._getFilters();

        return filters[url];
    },
    _getFilters() {
        return JSON.parse(localStorage.getItem(FILTERS_KEY)) || {};
    },
    _add(item, key) {
        let items = this._get(key);

        items.unshift(item)
        items = _.uniqBy(items, 'sku')
        items = items.slice(0,20);

        return this._set(items, key);
    },
    _get(key) {
        let items = JSON.parse(localStorage.getItem(key));

        if (!items) {
            items = []
        }

        return items;
    },
    _set(items, key) {
        return localStorage.setItem(key, JSON.stringify(items));
    },
    _has(item, key) {
        const founded = this._get(key).find((i) => i.sku === item.sku);

        return !!founded;
    },
    _remove(item, key) {
        const items = this._get(key).filter((i) => i.sku !== item.sku);

        return this._set(items, key);
    },
    _clear(key) {
        return localStorage.removeItem(key);
    }
}
