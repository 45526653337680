<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import CategoryPage from '@/views/CategoryPage';
import { getCollectionProducts } from '../services/api.service';

export default {
  name: 'CollectionPage',
  mixins: [
    CategoryPage
  ],
  metaInfo() {
    if (!this.collection) {
      return {};
    }

    return {
      title: `/ ${this.collection.title}`
    };
  },
  computed: {
    ...mapState({
      isFetching: state => state.collection.isFetching,
      collection: state => state.collection.collection,
      filters: state => state.collection.filters,
    }),
    ...mapGetters('collection', {
      crumbs: 'getCrumbs',
    }),
  },
  methods: {
    ...mapActions('collection', [
      'fetchCollection',
    ]),
    loadData() {
      this.fetchCollection(this.$route.params.slug);
    },
    loadProducts($state) {
      this.isProductsFetching = true;

      getCollectionProducts(this.$route.params.slug, this.appliedFilter, this.page, this.perPage, false)
          .then((products) => {
            this.products = [...this.products, ...products];
            products.length < this.perPage ? $state.complete() : $state.loaded();
            this.page += 1;
          })
          .catch(() => {
            $state.complete();
          })
          .finally(() => {
            this.isProductsFetching = false;
          });
    }
  },
}
</script>
