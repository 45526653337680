<template>
  <div class="CategoryItem" :class="{recently: hasViewed}">
    <img
      v-if="imageUrl"
      class="image"
      :src="imageUrl"
    />
    <div class="description">
      <span>{{ hasViewed ? 'Просмотренные товары' : description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryItem',
  props: {
    imageUrl: String,
    description: String,
    hasViewed: Boolean,
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.CategoryItem {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 40px 0 #e7ac84;

  &.recently {
    background: white;
  }

  // @include media-rule(mobile) {
  //   width: 160px;
  //   height: 160px;
  // }
  // @include media-rule(desktop) {
  //   width: 190px;
  //   height: 190px;
  // }
  // @include media-rule(fullhd) {
  //   width: 240px;
  //   height: 240px;
  // }
  // @include media-rule(twok) {
  //   width: 330px;
  //   height: 330px;
  // }

  .image {
    display: block;

    object-fit: contain;
    width: 100%;
    height: 100%;

    max-width: calc((100vw - $SidePaddingMB * 2 - $CategoryGapMB * 1) / 2);
    max-height: calc((100vw - $SidePaddingMB * 2 - $CategoryGapMB * 1) / 2);

    @media screen and (min-width: $BreakpointSM) {
      max-width: calc((100vw - $SidePaddingSM * 2 - $CategoryGapSM * 2) / 3);
      max-height: calc((100vw - $SidePaddingSM * 2 - $CategoryGapSM * 2) / 3);
    }

    @media screen and (min-width: $BreakpointMD) {
      max-width: calc((100vw - $SidePaddingMD * 2 - $CategoryGapMD * 3) / 4);
      max-height: calc((100vw - $SidePaddingMD * 2 - $CategoryGapMD * 3) / 4);
    }

    @media screen and (min-width: $BreakpointLG) {
      max-width: calc((100vw - $SidePaddingLG * 2 - $CategoryGapLG * 5) / 6);
      max-height: calc((100vw - $SidePaddingLG * 2 - $CategoryGapLG * 5) / 6);
    }
  }
  .description {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #FFFFFF;
    font-weight: bold;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    @include ripple-elm(#fff);
    > span {
      position: relative;
      z-index: 1;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(156, 156, 156, 0.85);
      mix-blend-mode: multiply;
      z-index: 0;
    }

    @include media-rule(mobile) {
      min-height: 48px;
      padding: 6px;
      @include font-variant(14, 18, 600);
    }
    @include media-rule(desktop) {
      min-height: 60px;
      padding: 6px;
      @include font-variant(16, 24, 600);
    }
    @include media-rule(fullhd) {
      min-height: 72px;
      padding: 8px;
      @include font-variant(20, 28, 600);
    }
    @include media-rule(twok) {
      min-height: 94px;
      padding: 11px;
      @include font-variant(28, 36, 600);
    }
  }

  &:active .description:after {
    @include ripple-action;
  }
}
</style>
