import { createRouter, createWebHistory } from "vue-router";
import LockScreen from "@/views/LockScreen";
import CatalogPage from "@/views/CatalogPage";
import CategoryPage from '@/views/CategoryPage';
import SearchPage from '@/views/SearchPage';
import ProductPage from '@/views/ProductPage';
import RecentlyPage from "@/views/RecentlyPage";
import CollectionPage from "@/views/CollectionPage";
import FavouritesPage from "@/views/FavouritesPage";

const routes = [
  {
    path : "/",
    name: "LockScreen",
    component : LockScreen,
  },
  {
    path : "/catalog",
    name: "Catalog",
    component : CatalogPage,
  },
  {
    path : "/recently",
    name: "Recently",
    component : RecentlyPage,
  },
  {
    path : "/favourites",
    name: "Favourites",
    component : FavouritesPage,
  },
  {
    path : "/category/:id",
    name: "Category",
    component : CategoryPage,
  },
  {
    path : "/collection/:slug",
    name: "Collection",
    component : CollectionPage,
  },
  {
    path : "/search",
    name: "Search",
    component : SearchPage,
  },
  {
    path : "/category/:category/product/:id",
    name: "Product",
    component : ProductPage,
  },
];

const router = createRouter({
  history : createWebHistory(),
  routes,
})

export default router;
