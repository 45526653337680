<template>
  <div class="SearchField" :class="{'-active': isActive}">
    <div
      class="field"
      @click="handleClick"
      v-bind:class="{ active: isActive, minimized: isMinimized }"
    >
      <SvgIcon name="search" class="magnifier" :size="32" />
      <AppTypehead
        id="search"
        ref="typehead"
        placeholder="Найти"
        :items="items"
        :minInputLength="minInputLength"
        :itemProjection="itemProjectionFunction"
        @selectItem="selectItemEventHandler"
        @onInput="onInputEventHandler"
        :selectOnEnter="selectOnEnter"
      />
      <SimpleLoader v-if="isFetching" />
      <SvgIcon name="close" class="close" @click="handleClose" />
      <SvgIcon name="search" class="magnifier-right" :size="32" />
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/SvgIcon';
  import SimpleLoader from '@/components/SimpleLoader';
  import { searchProducts } from '../services/api.service';
  import AppTypehead from '@/components/AppTypehead';

  export default {
    name: 'SearchField',
    components: {
      SvgIcon,
      SimpleLoader,
      AppTypehead
    },
    props: {
      isMinimized: Boolean,
      onToggle: Function,
    },
    data() {
      return {
        items: [],
        input: '',
        minInputLength: 2,
        isFetching: false,
        isActive: false,
      }
    },
    watch: {
      isActive() {
        this.onToggle(this.isActive);
      },
    },
    mounted() {
      const lastSearch = this.$route.query.term;

      if (lastSearch) {
        this.$refs.typehead.setInput(lastSearch);
        this.onInputEventHandler({ input: lastSearch });
        this.isActive = true;
      }
    },
    methods: {
      handleClick() {
        this.isActive = true;
      },
      handleClose(event) {
        event.stopPropagation()
        this.isActive = false;
        this.$refs.typehead.clear();
      },
      itemProjectionFunction(item) {
        return `${item.title} (${item.sku})`;
      },
      selectItemEventHandler(item) {
        if (item) {
          this.$router.push(`/category/${item.category.slug}/product/${item.sku}`);
        }
      },
      selectOnEnter() {
        this.$router.push(`/search/?term=${this.input}`);
      },
      onInputEventHandler({ input }) {
        this.input = input;

        if (input.length < this.minInputLength) {
          return;
        }

        this.isFetching = true;
        this.items = [];

        searchProducts(input, {}, 1, 10)
          .then((products) => {
            this.items = products;
            this.isFetching = false;
          });
      }
    },
  }
</script>

<style lang="scss">
@import "../styles/variables";
@import "../styles/mixins";

.SearchField {
  position: relative;

  @media screen and (max-width: $BreakpointSM) {
    position: absolute;
    &.-active {
      left: 10px;
    }
    right: 52px;
    top: 30px;
  }

  .field {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .magnifier {
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .magnifier-right {
      transition: all .3s ease-in-out;
      margin-left: 10px;
      opacity: 0;
      position: relative;
      width: 0;
      overflow: hidden;
      cursor: pointer;
      z-index: -1;
    }

    .close {
      transition: all .3s ease-in-out;
      margin-left: 10px;
      opacity: 0;
      position: absolute;
      width: 0;
      right: 8px;
      @media screen and (min-width: $BreakpointSM) {
        right: 32px;
      }
      overflow: hidden;
      cursor: pointer;
      z-index: 1;
    }

    .simple-typeahead {
      
      .simple-typeahead-input {
        border: none;
        outline: none;
        background: transparent;
        border-radius: 30px;
        height: 40px;
        padding: 12px 48px 12px 36px;
        transition: all .3s ease-in-out;
        min-width: 0;
        font-size: 16px;
        line-height: 40px;
        width: unset;
      }

      .simple-typeahead-list-item {
        border: none !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
      }
    }

    &.active {
      .magnifier {
        z-index: -1;
        opacity: 0;
        width: 0;
      }

      .magnifier-right {
        opacity: 1;
        z-index: 1;
        @media screen and (max-width: $BreakpointSM) {
          display: none;
        }
      }

      .simple-typeahead {
        .simple-typeahead-input {
          background: #fff;
          min-width: 100%;
        }
      }

      .close {
        opacity: 1;
        z-index: 1;
        width: unset;
        
      }


    }
    @mixin minimized {
      .simple-typeahead {
        .simple-typeahead-input {
          width: 0;
          padding-right: 0;
        }
      }

      .magnifier-right {
        opacity: 1;
        z-index: 1;
        width: unset;
        min-width: 28px;
      }

      .close, .magnifier {
        display: none;
      }

      &.active {
        .simple-typeahead {
          .simple-typeahead-input {
            width: 100%;
          }
        }

        .close {
          @media screen and (min-width: $BreakpointSM) {
            right: 64px;
          }
          display: block;
        }
      }
    }
    &.minimized {
      @include minimized;
    }
    @include media-rule(mobile){
      @include minimized;
    }
  }
}
</style>
