<template>
  <div class="no-results">По заданным параметрам ничего не нашлось. Попробуйте сбросить фильтры.</div>
</template>

<script>
export default {
  name: 'NoProducts',
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";

.no-results {
  padding: 0 32px;
}
</style>
