import {createApp} from 'vue'
import loader from 'vue-ui-preloader';
import PrimeVue from 'primevue/config';
import { createMetaManager, plugin as vueMetaPlugin } from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/icons';
import '@/styles/global.scss';
import Vue3SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import mitt from 'mitt';
const emitter = mitt();

router.beforeEach(() => {
  window.scrollTo(0, 0);
})

const app = createApp(App)
  .use(store)
  .use(router)
  .use(loader)
  .use(Vue3SimpleTypeahead)
  .use(createMetaManager())
  .use(PrimeVue)
  .use(vueMetaPlugin);

app.config.globalProperties.emitter = emitter;
app.mount('#app')
