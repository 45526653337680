import axios from 'axios';
import qs from 'qs';

let searchAbortController;
const baseURL = process.env.VUE_APP_API_URL;
const apiService = axios.create({
  baseURL,
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  }
});

export const getImageUrl = (name) => {
  return name ? `${baseURL}/uploads/images/${name}` : null;
}

export const getThumbImageUrl = (name) => {
  return name ? `${baseURL}/media/cache/thumb/uploads/images/${name}` : null;
}

export const getCategories = () => {
  return apiService.get('/categories').then(response => response.data.categories);
};

export const getFilters = (term) => {
  return apiService.get('/filters', {
    params: { term },
  }).then(response => response.data.filters);
};

export const getCategory= (slug) => {
  return apiService.get(`/category/${slug}`).then(response => response.data);
};

export const getCollection= (slug) => {
  return apiService.get(`/collection/${slug}`).then(response => response.data);
};

export const getProducts = (categorySlug, filter, page, perPage) => {
  return apiService.get(`/category/${categorySlug}/products`, {
    params: { filter, page, perPage }
  }).then(response => response.data.products);
};

export const getProductsBySkuList = (skuList) => {
  return apiService.get(`/products`, {
    params: { skuList }
  }).then(response => response.data.products);
};

export const getCollectionProducts = (collectionSlug, filter, page, perPage) => {
  return apiService.get(`/collection/${collectionSlug}/products`, {
    params: { filter, page, perPage }
  }).then(response => response.data.products);
};

export const searchProducts = (term, filter, page, perPage, cancelPrevious = true) => {
  if (searchAbortController && cancelPrevious) {
    searchAbortController.abort();
  }

  searchAbortController = new AbortController();

  return apiService.get(`/products/search`, {
    signal: searchAbortController.signal,
    params: { term, filter, page, perPage }
  }).then(response => response.data.products);
};

export const getProduct= (categorySlug, sku) => {
  return apiService.get(`/category/${categorySlug}/product/${sku}`).then(response => response.data);
};
