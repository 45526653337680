<template>
<DotsLoader v-if="isFetching" />
<div v-if="viewedItems.length" class="RecentlyViewed">
  <h2 class="header">Просмотренные товары</h2>
  <ProductCarousel :items="viewedItems"/>
</div>
</template>

<script>
import ProductCarousel from '@/components/ProductCarousel';
import DotsLoader from '@/components/DotsLoader';
import storage from '@/services/storage.service'
import { getProductsBySkuList } from '../services/api.service';

export default {
  name: 'RecentlyViewed',
  components: {
    ProductCarousel,
    DotsLoader,
  },
  data() {
    return {
      viewedItems: [],
      isFetching: false,
    }
  },
  mounted() {
    this.isFetching = true;

    getProductsBySkuList(storage.getRecently().map(product => product.sku))
      .then((products) => {
        this.viewedItems = products;
      })
      .finally(() => {
        this.isFetching = false;
      });
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixins";

.RecentlyViewed {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    @include media-rule(desktop) {
      margin: 44px 0;
    }
    @include media-rule(fullhd) {
      margin: 64px 0;
    }
    @include media-rule(twok) {
      margin: 84px 0;
    }
    @include media-rule(mobile) {
      margin: 20px;
    }
  }

}
</style>
