<template>
  <div class="ThumbGallery" @mouseover="stopTimer" @mouseleave="restartTimer">
    <Carousel
      class="thumbnails"
      :value="images"
      :num-visible="5"
      :num-scroll="5"
      :circular="true"
      orientation="vertical"
      vertical-view-port-height="400px"
    >
      <template #item="slotProps">
        <div
          :class="['thumbnail-image', (activeImage === slotProps.index) ? 'active' : '']"
          @click="activateImage(slotProps.index)"
        >
          <ProductImage
            title=""
            :image="slotProps.data.small"
          />
        </div>
      </template>
    </Carousel>
    <div class="card-img">
      <img :src="currentImageUrl" alt="" @click="showSingle(currentImageUrl)" v-if="!!currentImageUrl" />
      <SvgIcon name="img-thumb" :size=500 v-else />
      <div class="actions" v-if="images.length > 1">
        <span @click="prevImage" class="prev">
          &#8249;
        </span>
        <span @click="nextImage" class="next">
          &#8250;
        </span>
      </div>
    </div>
    <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="lightboxImages"
      :index="activeImage"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import Carousel from 'primevue/carousel';
import ProductImage from '@/components/ProductImage';
import SvgIcon from '@/components/SvgIcon';

export default {
  name: 'ThumbGallery',
  components: {
    VueEasyLightbox,
    Carousel,
    ProductImage,
    SvgIcon,
  },
  data() {
    return {
      activeImage: 0,
      autoSlideTimeout: null,
      stopSlider: false,
      timeLeft: 0,
      timerInterval: null,
      countdownInterval: 10,
      imgs: '', // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      currentImageUrl: null,
    }
  },
  computed: {
    lightboxImages() {
      return this.images.map(image => image.big);
    },
    progressBar() {
      //Calculate the width of the progressbar
      return 100 - (this.timeLeft/this.autoSlideInterval) * 100;
    }
  },
  mounted() {
    this.activateImage(0);
  },
  methods: {
    changeCurrentImageUrl() {
      const url = this.images[this.activeImage].big ? this.images[this.activeImage].big : 'image';
      const img = new Image();

      img.src = url;

      img.onerror = () => {
        this.currentImageUrl = false;
      }

      img.onload = () => {
        this.currentImageUrl = url.replace(/[)]/g,'\\)').replace(/[(]/g,'\\(');
      }
    },
    // Go forward on the images array
    // or go at the first image if you can't go forward
    nextImage() {
      let active = this.activeImage + 1;
      if(active >= this.images.length) {
        active = 0;
      }
      this.activateImage(active);
    },
    // Go backwards on the images array
    // or go at the last image
    prevImage() {
      let active = this.activeImage - 1;
      if(active < 0) {
        active = this.images.length - 1;
      }
      this.activateImage(active);
    },
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
      this.changeCurrentImageUrl();
    },
    //Wait until 'interval' and go to the next image;
    startTimer(interval) {
      if(interval && interval > 0 && !this.stopSlider) {
        const self = this;
        clearTimeout(this.autoSlideTimeout);
        this.autoSlideTimeout = setTimeout(function() {
          self.nextImage();
          self.startTimer(self.autoSlideInterval);
        }, interval);
      }
    },
    //Stop the timer when hovering over the carousel
    stopTimer() {
      clearTimeout(this.autoSlideTimeout);
      this.stopSlider = true;
      clearInterval(this.timerInterval);
    },
    //Restart the timer(with 'timeLeft') when leaving from the carousel
    restartTimer() {
      this.stopSlider = false;
      clearInterval(this.timerInterval);
      this.startCountdown();
      this.startTimer(this.timeLeft);
    },
    //Start countdown from 'autoSlideInterval' to 0
    startCountdown() {
      if(!this.showProgressBar) return;
      const self = this;
      this.timerInterval = setInterval(function() {
        self.timeLeft -= self.countdownInterval;
        if(self.timeLeft <= 0) {
          self.timeLeft = self.autoSlideInterval;
        }
      }, this.countdownInterval);
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    showSingle(imageUrl) {
      this.imgs = imageUrl
      this.show()
    },
  },
  created() {
    //Check if startingImage prop was given and if the index is inside the images array bounds
    if(this.startingImage
        && this.startingImage >= 0
        && this.startingImage < this.images.length) {
      this.activeImage = this.startingImage;
    }

    //Check if autoSlideInterval prop was given and if it is a positive number
    if(this.autoSlideInterval && this.autoSlideInterval > this.countdownInterval) {
      this.startTimer(this.autoSlideInterval);
      this.timeLeft = this.autoSlideInterval;
      this.startCountdown();
    }
  },
  props: { startingImage: String, images: [], autoSlideInterval: Number, showProgressBar: Boolean}
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";

.ThumbGallery {
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: row;

  @include media-rule(mobile) {
    flex-direction: column;
  }
  @include media-rule(desktop) {
    gap: 30px;
  }
  @include media-rule(fullhd) {
    gap: 40px;
  }
  @include media-rule(twok) {
    gap: 50px;
  }
  .thumbnails {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    @include media-rule(mobile) {
      display: none;
    }

    .thumbnail-image {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.active {
        border: 1px solid black;
      }

      img {
        width: 100%;
        height: auto;
        transition: all 250ms;
        display: block;
      }
    }
  }
}

.card-img {
  position: relative;
  margin-bottom: 15px;
  border: 1px solid $light-gray;
  display: flex;
  width: 100%;
  padding: 8px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  & > img {
    display: block;
    object-fit: contain;
    margin: auto;
    width: 100%;
    height: 100%;
  }

  width: calc(100vw - 42px);
  height: calc(100vw - 42px);

  @media screen and (min-width: $BreakpointSM) {
    width: calc((100vw - 500px) / 2);
    height: calc((100vw - 500px) / 2);
  }

  @media screen and (min-width: $BreakpointMD) {
    width: calc((100vw - 700px) / 2);
    height: calc((100vw - 700px) / 2);
  }

  // @include media-rule(desktop) {
  //   padding: 30px;
  //   & > img {
  //     width: 300px;
  //     height: 300px;
  //   }
  // }
  // @include media-rule(fullhd) {
  //   padding: 40px;
  //   & > img {
  //     width: 400px;
  //     height: 400px;
  //   }
  // }
  // @include media-rule(twok) {
  //   padding: 50px;
  //   & > img {
  //     width: 500px;
  //     height: 500px;
  //   }
  // }

}


  .actions {
    display: none;
    font-size: 1.5em;
    height: 40px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: #585858;

    @include media-rule(mobile) {
      display: flex;
    }

    & > span {
      cursor: pointer;
      transition: all 250ms;
      font-size: 45px;
    }

    & > span.prev {
      margin-left: 5px;
    }

    & > span.next {
      margin-right: 5px;
    }

    & > span:hover {
      color: #eee;
  }
}
</style>
