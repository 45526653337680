<template>
  <div>

  </div>
  <router-link :to="'/catalog'" replace class="link" >
    <BaseButton variant="orange-light" class="to-catalog">
      <b>Наш каталог</b>
    </BaseButton>
  </router-link>
  <video autoplay muted loop class="video">
    <source :src="require('./../assets/video/Cover&Photos_big_logo.mp4')" type="video/mp4">
  </video>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import isKiosk from '../helpers/isKiosk';

export default {
  name: 'LockScreen',
  components: {BaseButton},
  mounted() {
    if (!isKiosk()) {
      this.$router.push('/catalog')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixins";

.to-catalog {
  font-size: 24px;
  line-height: 34px;
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translate(-50%, -50%);

  @include media-rule(fullhd) {
    bottom: 200px;
  }
  @include media-rule(twok) {
    bottom: 300px;
  }
}

.link {
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
}
.video {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
}
.LockScreen {
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //height: 100vh;
  //width: 100vw;
  //position: relative;
  //overflow: hidden;
  //.logo {
  //  position: relative;
  //  z-index: 2;
  //}
  //.stripes {
  //  position: absolute;
  //  left: 20%;
  //  top: 0;
  //  width: 100%;
  //  overflow: hidden;
  //  display: grid;
  //  justify-content: center;
  //  margin: auto;
  //  grid-template-columns: 1fr;
  //  transform: skewY(-55deg) scale(2) translateY(-26%);
  //  height: 100vw;
  //
  //  @include media-rule(mobile) {
  //    transform: skewY(-55deg) scale(2) translateY(-10%);
  //    height: 190vw;
  //  }
  //
  //  .item {
  //    &-1 {
  //      background: #477762;
  //    }
  //    &-2 {
  //      background: #EDF2F0;
  //    }
  //    &-3 {
  //      background: #FDFDFD;
  //    }
  //    &-4 {
  //      background: #FFFFFF;
  //    }
  //    &-5 {
  //      background: #FFFFFF;
  //    }
  //    &-6 {
  //      background: #EAC9B2;
  //    }
  //    &-7 {
  //      background: #FFAE8A;
  //    }
  //  }
  //}
}
</style>
