<template>
  <div class="crumbs-wrapper">
    <ul class="BreadCrumbs medium-fs">
      <li class="home">
        <SvgIcon @click="toUrl('/catalog')" name="catalog" :size=32 />
      </li>
      <li
        v-for="(crumb, index) in crumbs"
        :class="{ opened: isLast(index) }"
        :key="index"
      >
        <span @click="selected(crumb)">
          {{ typeof crumb === 'object' ? crumb.title : crumb }}
        </span>
      </li>
    </ul>
    <ul class="TopMenu medium-fs">
      <li v-if="currentRouteName !== 'Favourites'">
        <span @click="toUrl('/favourites')">Избранное</span>
      </li>
      <li v-if="currentRouteName === 'Favourites'">
        <span @click="clearFavourites">Очистить список избранных</span>
      </li>
      <li v-if="currentRouteName === 'Product' && collectionUrl">
        <span @click="toUrl(collectionUrl)">Все товары коллекции {{ collectionName }}</span>
      </li>
      <li v-if="currentRouteName !== 'Product'">
        <span @click="toUrl('/recently')">Просмотренные товары</span>
      </li>
    </ul>
    <BaseButton
        v-if="currentRouteName === 'Product'"
        variant="transparent"
        class="go-back"
        @click="backHandler">
      <SvgIcon name="arr-side-left" :size="16" />
      Назад
    </BaseButton>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import BaseButton from '@/components/BaseButton';
import storage from '@/services/storage.service'
import router from '@/router';

export default {
  name: 'BreadCrumbs',
  components: {BaseButton, SvgIcon},
  props: {
    crumbs: Array,
    collectionUrl: String,
    collectionName: String,
  },
  methods: {
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
    selected(crumb) {
      this.$emit('selected', crumb);
    },
    backHandler() {
      router.back()
    },
    toUrl(url) {
      this.$router.push(url);
    },
    clearFavourites() {
      storage.clearFavourites();
      this.$router.go();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";
.crumbs-wrapper {
  @include side-padding;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.go-back {
  display: none;
  align-items: center;
  padding-left: 7px;

  @media screen and (min-width: $BreakpointSM) {
    display: flex;
  }

}
.BreadCrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 22px 0;

  li {
    display: inline-block;
    flex-direction: row;
    padding: 0 ;
    align-items: center;
    justify-content: center;
    margin-right: .3em;
    &::before {
      content: '/';
      margin-right: .3em;
      color: $gray;
    }
    &:first-child::before {
      display: none;
    }
    > span {
      cursor: pointer;
    }
    &.opened > span {
      font-weight: 600;
      cursor: inherit;
    }
  }

  li.home {
    display: inline-block;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }

    @include media-rule(mobile) {
      width: 32px;
      height: 32px;
    }
    @include media-rule(desktop) {
      width: 24px;
      height: 24px;
    }
    @include media-rule(fullhd) {
      width: 32px;
      height: 32px;
    }
    @include media-rule(twok) {
      width: 32px;
      height: 32px;
    }
  }
}
.TopMenu {
  display: none;
  flex-direction: row;
  align-items: center;
  margin: 22px 10px 22px auto;

  @media screen and (min-width: $BreakpointSM) {
    display: flex;
  }

  li {
    display: inline-block;
    flex-direction: row;
    padding: 0;
    align-items: center;
    justify-content: center;
    margin-right: .3em;

    &::before {
      content: '|';
      margin-right: .3em;
      color: $gray;
    }

    &:first-child::before {
      display: none;
    }

    > span {
      cursor: pointer;
    }

    &.opened > span {
      font-weight: 600;
      cursor: inherit;
    }
  }
}
</style>
