import { getProducts, getProduct } from '../../services/api.service';

const state = {
  products: [],
  product: null,
  groupProducts: {},
  collectionProducts: [],
  page: 0,
  perPage: 100,
  isFetching: false,
  error: null,
};

const getters = {
  getCrumbs({ product }) {
    const crumbs = [];

    if (product) {
      crumbs.push({ title: product.category.title, id: product.category.slug });
      crumbs.push(product.sku);
    }

    return crumbs;
  }
};

const actions = {
  fetchProducts({ commit }, { categorySlug, filter, page, perPage }) {
    commit('fetchRequest');
    getProducts(categorySlug, filter, page, perPage)
      .then((products) => commit('fetchProductsSuccess', { products, page }))
      .catch((error) => commit('fetchError', { error }))
  },
  fetchProduct({ commit }, { categorySlug, sku }) {
    commit('fetchRequest');
    getProduct(categorySlug, sku)
      .then(({ product, collectionProducts, groupProducts }) => commit('fetchProductSuccess', { product, collectionProducts, groupProducts }))
      .catch((error) => commit('fetchError', { error }))
  }
};

const mutations = {
  fetchRequest(state) {
    state.isFetching = true;
    state.products = [];
    state.product = null;
    state.error = null;
  },
  fetchError(state, { error }) {
    state.isFetching = false;
    state.error = error;
  },
  fetchProductsSuccess(state, { products, page }) {
    state.products = page === 1 ? products : [...state.products, ...products];
    state.page = page;
    state.isFetching = false;
    state.error = null;
  },
  fetchProductSuccess(state, { product, collectionProducts, groupProducts }) {
    state.product = product;
    state.groupProducts = groupProducts;
    state.collectionProducts = collectionProducts;
    state.isFetching = false;
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
