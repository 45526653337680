<template>
<div class="ProductCarousel">
  <Carousel
      class="thumbnails"
      :value="items"
      :num-visible="4"
      :num-scroll="4"
      :circular="true"
      orientation="horizontal"
      :responsiveOptions="responsiveOptions"
  >
    <template #item="slotProps">
      <ProductLink
        :product="slotProps.data"
        :style="{textAlign: 'center'}"
        :imageUrl="getImage(slotProps.data)"
        :title="slotProps.data.title"
        :vendorCode = "slotProps.data.sku"
      />
    </template>
  </Carousel>
</div>
</template>

<script>
import { getThumbImageUrl } from '../services/api.service';
import ProductLink from '@/components/ProductLink'
import Carousel from 'primevue/carousel';

export default {
  name: 'ProductCarousel',
  props: {
    items: Array,
    isCollection: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Carousel,
    ProductLink,
  },
  methods: {
    getImage(product) {
      return getThumbImageUrl(product.images[0]);
    },
    savePosition() {

    }
  },
  data() {
    return {
      responsiveOptions: [
        {
          breakpoint: '1920px',
          numVisible: 4,
          numScroll: 4
        },
        {
          breakpoint: '1280px',
          numVisible: 3,
          numScroll: 3
        },
        {
          breakpoint: '1024px',
          numVisible: 2,
          numScroll: 2
        },
        {
          breakpoint: '768px',
          numVisible: 2,
          numScroll: 2
        }
      ],
    };
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
.ProductCarousel {
  width: 80%;
  @media screen and (max-width: calc($BreakpointSM - 1px)) {
    width: 100%;
  }
}
</style>
