<template>
  <loader object="#eac9b2" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="0" name="dots"></loader>
</template>

<script>
  export default {
    name: 'DotsLoader',
  }
</script>
<style>
.p {
  background: #343a40;
}
</style>
