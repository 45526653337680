<template>
  <div class="CheckBox">
    <label>
      <input type="checkbox" :value="item.title" v-model="model">
      <div class="custom-view">
        <SvgIcon name="check" />
      </div>
      <div class="title">{{ item.title }}</div>
    </label>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';

export default {
  name: 'CheckBox',
  components: { SvgIcon },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    variant: String,
    modelValue: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";

.CheckBox {
  position: relative;
  margin-bottom: 12px;
  label {
    user-select: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    input[type='checkbox'] {
      position: absolute;
      z-index: -1;
      opacity: 0;

      & + .custom-view {
        @include ripple(white);

        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid $gray;
        border-radius: 3px;
        margin-right: 12px;
        background: white;
        transition: background .3s ease-in-out;
        margin-top: 3px;
        width: 16px;
        height: 16px;

        @include media-rule(mobile) {
          margin-top: 0;
        }

        @include media-rule(desktop) {
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }

        > svg {
          opacity: 0;
          width: 8px;
          height: 6px;
        }
      }

      &:checked + .custom-view {
        background: $bg-secondary;
        border-color: $bg-secondary;

        > svg {
          opacity: 1;
        }
      }
    }
    .title {
      word-break: break-all;
    }
  }
}
</style>