<template>
  <DotsLoader v-if="isFetching || isViewedFetching" />
  <CatalogHeader/>
  <div class="field">
    <div :class="{'categories': true}">
      <router-link :to="'/category/' + item.slug" v-for="(item, index) in categories" :key="index">
        <CategoryItem
          :description="item.title"
          :imageUrl="getThumbImageUrl(item.image)"
          :id="item.slug"
        />
      </router-link>
      <router-link :to="'/recently'" v-if="!!viewed">
        <CategoryItem
          :imageUrl="getThumbImageUrl(viewed.images[0])"
          :hasViewed="!!viewed"
        />
      </router-link>
    </div>
    <div class="load-more" role="button" v-if="!!viewed" @click="scrollDown" >
      <SvgIcon name="down_arr" :size="32" />
    </div>
  </div>
  <RecentlyViewed />
  <CatalogFooter />
</template>

<script>

import { mapState, mapActions } from 'vuex';
import { getThumbImageUrl, getProductsBySkuList } from '../services/api.service';
import storage from '@/services/storage.service'
import viewedItems from '@/demo-files/viewed-items.json';
import CatalogHeader from '@/components/CatalogHeader';
import CatalogFooter from '@/components/CatalogFooter';
import DotsLoader from '@/components/DotsLoader';
import CategoryItem from '@/components/CategoryItem';
import SvgIcon from '@/components/SvgIcon';
import RecentlyViewed from '@/components/RecentlyViewed';

export default {
  name: 'CatalogPage',
  data() {
    return {
      isViewedFetching: false,
      getThumbImageUrl,
      viewedItems,
      viewed: null,
    }
  },
  mounted () {
    this.fetchCategories();

    this.isViewedFetching = true;

    getProductsBySkuList(storage.getRecently().map(product => product.sku))
      .then((products) => {
        this.viewed = products[0] || null;
      })
      .finally(() => {
        this.isViewedFetching = false;
      });
  },
  computed: {
    ...mapState({
      isFetching: state => state.category.isFetching,
      categories: state => state.category.categories
    }),
  },
  methods: {
    ...mapActions('category', [
        'fetchCategories',
    ]),
    scrollDown() {
      window.scrollTo({
        top: document.body.scrollHeight || document.documentElement.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  components: {
    SvgIcon,
    CatalogHeader,
    CatalogFooter,
    CategoryItem,
    RecentlyViewed,
    DotsLoader,
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";

.field {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: $bg-secondary;
  width: 100%;

  padding: $SidePaddingMB 0;

  @media screen and (min-width: $BreakpointSM) {
    margin-top: 0;
    padding: $SidePaddingSM 0;
  }

  @media screen and (min-width: $BreakpointMD) {
    padding: $SidePaddingMD 0;
  }

  @media screen and (min-width: $BreakpointLG) {
    padding: $SidePaddingLG 0;
  }

 

  .categories {
    // @include side-padding;

    display: grid;
    width: 100%;

    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: $CategoryGapMB;
    grid-row-gap: 48px;

    padding-left: $SidePaddingMB;
    padding-right: $SidePaddingMB;

    @media screen and (min-width: $BreakpointSM) {
      grid-template-columns: repeat(3, 1fr);
       grid-column-gap: $CategoryGapSM;
      padding-left: $SidePaddingSM;
      padding-right: $SidePaddingSM;
    }

    @media screen and (min-width: $BreakpointMD) {
      grid-template-columns: repeat(4, 1fr);
       grid-column-gap: $CategoryGapMD;
      padding-left: $SidePaddingMD;
      padding-right: $SidePaddingMD;
    }

    @media screen and (min-width: $BreakpointLG) {
      grid-template-columns: repeat(6, 1fr);
       grid-column-gap: $CategoryGapLG;
      padding-left: $SidePaddingLG;
      padding-right: $SidePaddingLG;
    }

    // @include media-rule(mobile) {
    //   grid-column-gap: 16px;
    //   grid-row-gap: 48px;
    //   grid-template-columns: repeat(2, 1fr);
    // }
    // @include media-rule(desktop) {
    //   grid-column-gap: 10px;
    //   grid-row-gap: 60px;
    //   grid-template-columns: repeat(6, 1fr);
    // }
    // @include media-rule(fullhd) {
    //   grid-column-gap: 48px;
    //   grid-row-gap: 144px;
    //   grid-template-columns: repeat(6, 1fr);
    // }
    // @include media-rule(twok) {
    //   grid-column-gap: 66px;
    //   grid-row-gap: 154px;
    //   grid-template-columns: repeat(6, 1fr);
    // }
  }
  .load-more {
    margin: auto;
    cursor: pointer;
    @include media-rule(desktop) {
      margin-top: 70px;
    }
    @include media-rule(fullhd) {
      margin-top: 96px;
    }
    @include media-rule(twok) {
      margin-top: 132px;
    }
    @include media-rule(mobile) {
      margin-top: 30px;
    }

  }
}

</style>
