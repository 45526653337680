<template>
  <div :class="{image: true, active: active}" :title="title" v-if="!!img">
    <div class="bg-holder" :style="{backgroundImage: `url(${img})`}"/>
  </div>
  <div v-else :class="{image: true, active: active}">
    <SvgIcon name="img-thumb" :size=200 />
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';

export default {
  name: 'ProductImage',
  components: {
    SvgIcon,
  },
  props: {
    title: String,
    image: String,
    active: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      img: null
    };
  },
  mounted() {
    this.imageUrl()
  },
  methods: {
    imageUrl() {
      const url = this.image ? this.image : 'image';
      const img = new Image();

      img.src = url;

      img.onerror = () => {
        this.img = false;
      }

      img.onload = () => {
        this.img = url.replace(/[)]/g,'\\)').replace(/[(]/g,'\\(');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "../styles/variables";
  @import "../styles/mixins";

  .image {
    border: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    padding: 8px;

    &.active {
      border: 1px solid #000;
    }

    & > .bg-holder {
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    & > svg {
      width: 100%;
      height: 100%;
      display: block;
      margin: 0;
    }
  }
</style>
