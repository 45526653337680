<template>
  <div class="CheckBox">
    <label>
      <input type="checkbox" :value="item.title" v-model="model">
      <div class="title">{{ item.title }}</div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckButton',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    variant: String,
    modelValue: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";

.CheckBox {
  position: relative;
  display: inline-flex;
  margin: 0 10px 10px 0;
  overflow: hidden;

  label {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    input[type='checkbox'] {
      position: absolute;
      z-index: -1;
      opacity: 0;

      & + .title {
        @include ripple(white);

        padding: 6px 8px;
        border: 1px solid $light-gray;
        border-radius: 12px;
        word-break: break-all;
        transition: background .3s ease-in-out;
      }
      &:checked  + .title {
        background: $bg-secondary;
        border-color: $bg-secondary;
      }
    }
  }
}
</style>
