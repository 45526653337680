<template>
  <div class="ProductLink" v-bind:class="[variant]">
    <AppFavourite class="favourite" :product="product" />
    <router-link :to="`/category/${product.category.slug}/product/${product.sku}`">
      <div class="image" v-if="!!img">
        <div class="bg-holder" :style="{backgroundImage: img ? 'url(' + img + ')' : ''}"/>
      </div>
      <div v-else class="image">
        <SvgIcon name="img-thumb" :size=200 />
      </div>
      <div class="title" v-if="title">{{title}}</div>
      <div class="vendor" v-if="vendorCode">
        <small>
          <span class="title">Артикул</span> <nobr>{{vendorCode}}</nobr>
        </small>
      </div>
    </router-link>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import AppFavourite from '@/components/AppFavourite';

export default {
  name: 'ProductLink',
  components: {
    SvgIcon,
    AppFavourite,
  },
  props: {
    product: Object,
    imageUrl: String,
    title: String,
    vendorCode: String,
    variant: String,
    showFavourite: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      img: null
    };
  },
  mounted() {
    this.image()
  },
  methods: {
    image() {
      const url = this.imageUrl ? this.imageUrl : 'image';
      const img = new Image();

      img.src = url;

      img.onerror = () => {
        this.img = false;
      }

      img.onload = () => {
        this.img = this.imageUrl.replace(/[)]/g,'\\)').replace(/[(]/g,'\\(');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";

.ProductLink {
  width: calc($ThumbnailWidthMB + 16px * 2 + 2px);
  @media screen and (min-width: $BreakpointSM) {
    width: calc($ThumbnailWidthSM + 16px * 2 + 2px);
  }

  position: relative;
  margin: 0 auto 32px auto;

  .favourite {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
  }

  .image {
    width: calc($ThumbnailWidthMB + 16px * 2 + 2px);
    height: calc($ThumbnailWidthMB + 16px * 2 + 2px);
    
    @media screen and (min-width: $BreakpointSM) {
      width: calc($ThumbnailWidthSM + 16px * 2 + 2px);
      height: calc($ThumbnailWidthSM + 16px * 2 + 2px);
    }
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid $light-gray;

    & > .bg-holder {
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;

      width: $ThumbnailWidthMB;
      height: $ThumbnailWidthMB;
      
      @media screen and (min-width: $BreakpointSM) {
        width: $ThumbnailWidthSM;
        height: $ThumbnailWidthSM;
      }

    }
    & > svg {
      width: 100%;
      height: 100%;
      display: block;
      margin: 0;
    }
  }
  .title {
    margin-bottom: 8px;
    color: #000;
  }
  .vendor {
    color: $dark-gray;
    .title {
      color: $gray;
    }
  }

}
</style>
