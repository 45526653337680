<script>
  import Vue3SimpleTypeahead from 'vue3-simple-typeahead';

  export default {
    name: 'AppTypehead',
    mixins: [Vue3SimpleTypeahead],
    props: {
      selectOnEnter: Function,
    },
    computed: {
      filteredItems() {
        return this.items;
      },
    },
    methods: {
      setInput(input) {
        this.input = input;
      },
      clear() {
        this.input = '';
      },
      selectCurrentSelection() {
        if (this.selectOnEnter) {
          this.selectOnEnter(this.currentSelection);
        }
      },
    },
  }
</script>

<style scoped>
  .simple-typeahead {
    position: relative;
    width: 100%;
  }
  .simple-typeahead > input {
    margin-bottom: 0;
  }
  .simple-typeahead .simple-typeahead-list {
    position: absolute;
    width: 100%;
    border: none;
    max-height: 400px;
    overflow-y: auto;
    border-bottom: 0.1rem solid #d1d1d1;
    z-index: 9;
  }
  .simple-typeahead .simple-typeahead-list .simple-typeahead-list-header {
    background-color: #fafafa;
    padding: 0.6rem 1rem;
    border-bottom: 0.1rem solid #d1d1d1;
    border-left: 0.1rem solid #d1d1d1;
    border-right: 0.1rem solid #d1d1d1;
  }
  .simple-typeahead .simple-typeahead-list .simple-typeahead-list-footer {
    background-color: #fafafa;
    padding: 0.6rem 1rem;
    border-left: 0.1rem solid #d1d1d1;
    border-right: 0.1rem solid #d1d1d1;
  }
  .simple-typeahead .simple-typeahead-list .simple-typeahead-list-item {
    cursor: pointer;
    background-color: #fafafa;
    padding: 0.6rem 1rem;
    border-bottom: 0.1rem solid #d1d1d1;
    border-left: 0.1rem solid #d1d1d1;
    border-right: 0.1rem solid #d1d1d1;
  }

  .simple-typeahead .simple-typeahead-list .simple-typeahead-list-item:last-child {
    border-bottom: none;
  }

  .simple-typeahead .simple-typeahead-list .simple-typeahead-list-item.simple-typeahead-list-item-active {
    background-color: #e1e1e1;
  }
</style>
